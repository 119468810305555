import React, { useRef } from "react";
import Modal from "react-modal";
import { Invoice } from "../../layout/invoice";
import { printDocument } from "../../utils/functions";

export const SellDetailsModal = ({ isOpen, onRequestClose, sellDetails }) => {
  const pdfRef = useRef();

  if (!sellDetails) return null;

  const {
    sell_id,
    customer_name,
    customer_address,
    customer_phone_number,
    products = [], // Default to an empty array if products is undefined
  } = sellDetails;
  const printInvoice = async () => {
    await printDocument("inv_content");
  };

  return (
    <Modal
      isOpen={isOpen} 
      onRequestClose={onRequestClose}
      contentLabel="Sell Details"
    >
      <div ref={pdfRef} className="p-3">
        <h2>Cash Memo</h2>
        <div>
          <p>
            <strong>Sell ID:</strong> {sell_id}
          </p>
          <p>
            <strong>Customer Name:</strong> {customer_name}
          </p>
          <p>
            <strong>Customer Address:</strong> {customer_address}
          </p>
          <p>
            <strong>Customer Phone Number:</strong> {customer_phone_number}
          </p>
        </div>
        <div>
          <h3>Products</h3>
          {products.map((product, index) => (
            <div key={index}>
              <p>
                <strong>Product ID:</strong> {JSON.parse(product.product_id)?.join(", ")}

              </p>
              <p>
                <strong>Quantity:</strong> {JSON.parse(product.quantity)?.join(", ")}
              </p>
              <p>
                <strong>Price:</strong> {JSON.parse(product.price)?.join(", ")}
              </p>
            </div>
          ))}
        </div>

        <div className="d-flex">
        <button onClick={onRequestClose} className="btn btn-danger btn-sm m-1" >Close</button>
        <button onClick={printInvoice} className="btn btn-primary btn-sm m-1">
        Print
      </button>
        </div>
        
        <div
          id="inv_content"
          style={{
            minHeight: "max-content",
            display: "none",
          }}
        >
          <Invoice data={sellDetails} />
        </div>
      </div>
      
    </Modal>
  );
};
