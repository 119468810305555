import Modal from "react-bootstrap/Modal";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "../../api/axios";

const UpdateModal = ({ show, handleClose, userRoles, userId, updateData }) => {
  const [routes, setRoutes] = useState([
    { name: "category", allow: false },
    { name: "inventory", allow: false },
    { name: "allUser", allow: false },
    { name: "vendor", allow: false },
    { name: "notice", allow: false },
    { name: "noticeRead", allow: false },
    { name: "assignRole", allow: false },
    { name: "sell", allow: false },
    { name: "transfer", allow: false },
    { name: "modifyTransfer", allow: false },
    { name: "profile", allow: false },
    { name: "waste", allow: false },
    { name: "wasteRead", allow: false },
    { name: "requisition", allow: false },
    { name: "modifyRequisition", allow: false },
    { name: "settings", allow: false },
  ]);
  useEffect(() => {
    if (userRoles && Array.isArray(userRoles)) {
      let UpUserRoles = [];
      for (const e of routes) {
        const role = userRoles.find((r) => r.name === e.name);
        UpUserRoles.push(role || e);
      }
      setRoutes(UpUserRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles]);

  const handleUpdateUserRole = async () => {
    try {
      const response = await axios.put("/update-user-role", {
        user_id: userId,
        data: routes,
      });

      if (response.status === 200) {
        //update user roles state here
        updateData();
        handleClose(); // Close the modal after successful update
      }
    } catch (error) {
      console.error("Error updating role:", error);
      // Handle error
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="custom-modal">
      <div className="card shadow bg-body rounded">
        <div className="card-header">
          <Modal.Header closeButton>
            <Modal.Title>Assign Pages</Modal.Title>
          </Modal.Header>
        </div>
      </div>

      <Modal.Body
        style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
      >
        <div className="card shadow p-3 mb-0 bg-body rounded">
          <div className="card-body" style={{ paddingRight: 0 }}>
            <Form.Group>
              <ul className="list-group list-group-flush">
                {routes.map((path, i) => (
                  <li className="list-group-item" key={path.name || i}>
                    <Form.Group>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{path.name?.toUpperCase()}</span>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Check
                              type="switch"
                              id={`${path.name}-switch1`}
                              label=""
                              defaultChecked={path.allow}
                              key={path.allow}
                              onChange={(e) => {
                                const updatedRoutes = [...routes];
                                updatedRoutes[i].allow = e.target.checked;
                                setRoutes(updatedRoutes);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </li>
                ))}
              </ul>
            </Form.Group>
          </div>
          <Button
            className="col-md-3"
            variant="primary"
            onClick={handleUpdateUserRole}
          >
            Update
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
