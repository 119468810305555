import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "../../api/axios";
// import "../style.css";

const RequisitionGraph = () => {
  const [data, setData] = useState({
    categories: [],
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      // title: {
      //   text: 'Requisition Graph',
      //   align: 'left'
      // },
      xaxis: {
        categories: [],
      },
    },
  });

  useEffect(() => {
    axios
      .get("get-all-requisition") // Assuming this endpoint returns requisition data
      .then((response) => {
        const requisitionData = response.data;
        const categories = requisitionData.map((item) => item.product_name);
        const seriesData = requisitionData.map((item) => item.quantity);

        setData((prevState) => ({
          ...prevState,
          categories: categories,
          series: [{ data: seriesData }],
          options: {
            ...prevState.options,
            xaxis: { categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching requisition data:", error);
      });
  }, []);

  return (
    <div className="card mb-5 border-0 shadow">
      <div className="card-header py-3 text-center">
        <b>Requisition Graph</b>
      </div>
      <div className="card-body">
        <div id="chart">
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="bar"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default RequisitionGraph;
