import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./layout/Layout";
import Register from "./pages/Register";
import { ToastContainer } from "react-toastify";
import routesConfig from "./routesConfig";
import FileUploadPage from "./pages/file";
import { AuthProvider } from "./context/auth";
import { SettingsProvider } from "./context/settings";
import { Appearance } from "./components/appearance";
import "./assets/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./pages/style.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [loggedInUser, setLoggedInUser] = useState(null);

  const handleLogin = (user_id) => {
    setLoggedInUser(user_id);
    sessionStorage.setItem("user_id", user_id);
  };

  const isLoggedIn = () => {
    return loggedInUser !== null || sessionStorage.getItem("user_id") !== null;
  };

  return (
    <AuthProvider>
      <SettingsProvider>
        <Appearance />
        <Routes>
          {isLoggedIn() ? (
            <Route path="/" element={<Layout />}>
              {routesConfig.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Component
                      loggedInUser={sessionStorage.getItem("user_id")}
                    />
                  }
                />
              ))}
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route
            path="/register"
            element={isLoggedIn() ? <Navigate to="/login" /> : <Register />}
          />
          <Route path="/file" element={<FileUploadPage />} />
        </Routes>
        <ToastContainer />
      </SettingsProvider>
    </AuthProvider>
  );
}

export default App;
