import {Link} from "react-router-dom";
 import  "./403.css";

const Error403 = () => {
  return (
    <div className="errorBody">
      <div className="errorBody_container">
        <div className="ntfnd">
          <div className="error">
            <h1>403</h1>
          </div>
          <h2>access forbidden</h2>
          <p>
            You do not have permission to access the document or program that
            you requested.
            <br />
            <Link to="/dashboard">Return to homepage</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error403;