// routesConfig.js

import Home from "./pages/Dashboard/Home";
import { CategoryAdd, CategoryGet } from "./pages/category/CategoryIndex";
import { RoleAdd, RoleGet } from "./pages/role/Role";
import {
  FavouriteListAdd,
  FavouriteListGet,
} from "./pages/favouriteList/FavouriteListIndex";
import { UnfavListAdd, UnfavListGet } from "./pages/unfav_list/UnfavList";
import { HomeIndex } from "./pages/HomePage/HomeIndex";
import { InventoryAdd, InventoryGet } from "./pages/Inventory/InventoryIndex";
import { BlockIndexAdd, BlockIndexGet } from "./pages/Block/BlockIndex";
import {
  AllUserIndexAdd,
  AllUserIndexGet,
  AssignRoleUser,
} from "./pages/AllUser/AllUserIndex";
import SellIndexAdd from "./pages/Sell/SellIndex";
import {
  NoticeIndexAdd,
  NoticeIndexGet,
  NoticeIndexRead,
} from "./pages/Notice/NoticeIndex";
import {
  TransferIndexAdd,
  TransferIndexGet,
} from "./pages/Transfer/TransferIndex";
import {
  RequisitionIndexAdd,
  RequisitionIndexGet,
} from "./pages/Requisition/RequisitionIndex";
import { ProfileIndex } from "./pages/Profile/ProfileIndex";
import { WasteIndexGet, WasteIndexRead } from "./pages/Waste/WasteIndex";
import { VendorIndexAdd, VendorIndexGet } from "./pages/Vendor/VendorIndex";
import {
  SettingsIndexAdd,
  SettingsIndexGet,
} from "./pages/Settings/SettingsIndex";
import { Invoice } from "./layout/invoice";
import SellIndexGet from "./pages/Sell/SellList";
import TransactionGet from "./pages/Transaction/Transaction";
import {
  AccountCategoryAdd,
  AccountCategoryGet,
} from "./pages/Account/Category/categoryIndex";

import { ExternalInventoryAdd } from "./pages/Account/JobWork/externalInventoryIndex";
import { ExternalInventoryGet } from "./pages/Account/JobWork/externalInventoryListIndex.jsx";
import { OthersAccountIndexAdd } from "./pages/Account/Others/othersAccountIndex.jsx";
import { AccountOtherGet } from "./pages/Account/Others/othersAccountIndexList.jsx";
import { BankAccountIndex } from "./pages/Account/Bank/bankAccountIndex.jsx";
import { BankAccountGet } from "./pages/Account/Bank/bankAccountIndexList.jsx";

const routesConfig = [
  { path: "/dashboard", component: Home },
  { path: "/category", component: CategoryGet },
  { path: "/category/add", component: CategoryAdd },
  { path: "/role", component: RoleGet },
  { path: "/role/add", component: RoleAdd },
  { path: "/fav-list", component: FavouriteListGet },
  { path: "/fav-list/add", component: FavouriteListAdd },
  { path: "/unfav-list", component: UnfavListGet },
  { path: "/unfav-list/add", component: UnfavListAdd },
  { path: "/home", component: HomeIndex },
  { path: "/inventory", component: InventoryGet },
  { path: "/inventory/add", component: InventoryAdd },
  { path: "/block", component: BlockIndexGet },
  { path: "/block/add", component: BlockIndexAdd },
  { path: "/all-user", component: AllUserIndexGet },
  { path: "/all-user/add", component: AllUserIndexAdd },
  { path: "/notice", component: NoticeIndexGet },
  { path: "/notice/add", component: NoticeIndexAdd },
  { path: "/all-user/assign-role", component: AssignRoleUser },
  { path: "/sell", component: SellIndexGet },
  { path: "/sell/add", component: SellIndexAdd },
  { path: "/transfer", component: TransferIndexGet },
  { path: "/transfer/add", component: TransferIndexAdd },
  { path: "/profile", component: ProfileIndex },
  { path: "/invoice", component: Invoice },
  { path: "/waste", component: WasteIndexGet },
  { path: "/waste/aprroved", component: WasteIndexRead },
  { path: "/vendor/add", component: VendorIndexAdd },
  { path: "/vendor", component: VendorIndexGet },
  { path: "/requisition", component: RequisitionIndexGet },
  { path: "/requisition/add", component: RequisitionIndexAdd },
  { path: "/notice/all", component: NoticeIndexRead },
  { path: "/settings", component: SettingsIndexAdd },
  { path: "/settings/get", component: SettingsIndexGet },
  { path: "/transactions", component: TransactionGet },
  { path: "/account/category/add", component: AccountCategoryAdd },
  { path: "/account/category", component: AccountCategoryGet },
  { path: "/account/inventory/add", component: ExternalInventoryAdd },
  { path: "/account/inventory", component: ExternalInventoryGet },
  { path: "/account/others/add", component: OthersAccountIndexAdd },
  { path: "/account/others", component: AccountOtherGet },
  { path: "/account/bank/add", component: BankAccountIndex },
  { path: "/account/bank", component: BankAccountGet },
];

export default routesConfig;
