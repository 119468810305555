import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/auth";
import axios from "../../api/axios";
import Error403 from "../../components/Error/403";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import DataTable from "../../components/table";

const SellIndexGet = () => {
  const [sells, setSells] = useState([]);
  const [sellToUpdate, setSellToUpdate] = useState(null);
  const [sellIdToDelete, setSellIdToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [inventories, setProducts] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const table = useRef(null);

  const { user } = useContext(AuthContext);

  const itemsPerPage = 15;

  const fetchSells = async () => {
    try {
      setSells([]);
      const response = await axios
        .get(
          `/get-sell?page=${currentPage}&itemsPerPage=${itemsPerPage}&q=${searchTerm}`
        )
        .then((response) => response.data);
      setSells(response?.sells || []);

      setTotalItems(response?.pagination?.totalItems || 0);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-inventory");
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setLoading(false);
    }
  };

  const fetchUserlists = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-user");
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchUserlists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSells();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchTerm]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "sell");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (sell_id) => {
    setSellIdToDelete(sell_id);
    setShowDeleteModal(true);
  };

  const handleEdit = (sell) => {
    setSellToUpdate(sell);
    setShowUpdateModal(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await axios.put("/update-sell", {
        data: sellToUpdate,
      });

      if (response.status === 200) {
        console.log("Sell updated successfully:", response.data);
        fetchSells();

        toast.success("Sell updated successfully");
        setShowUpdateModal(false);
      }
    } catch (error) {
      console.error("Error updating sell:", error);
      toast.error("Failed to update sell");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("delete-sell", {
        data: { sell_id: sellIdToDelete },
      });
      console.log("Sell deleted successfully:", response.data);
      fetchSells();
      toast.success("Sell deleted successfully");
    } catch (error) {
      console.error("Error deleting sell:", error);
      toast.error("Failed to delete sell");
    } finally {
      setShowDeleteModal(false);
    }
  };

  console.log(currentPage);
  console.log(sells);
  return (
    <div className="content">
      <section className="content">
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link
                      to="/sell/add"
                      className="btn btn-primary float-right"
                    >
                      Add Sell
                    </Link>
                    {/* <CSVLink
                      data={csvData}
                      headers={headers}
                      filename={"sell_report.csv"}
                      title="Export CSV"
                      className="btn btn-secondary float-right mr-2"
                    >
                      <i className="fas fa-file-export mr-1"></i>
                    </CSVLink> */}
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Sell List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search By Sell Invoice No."
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value.toLowerCase());
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {sells.length > 0 && (
                    <DataTable
                      data={sells}
                      ref={table}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Sell Invoice No.</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Address</th>
                          <th className="text-center">Phone number</th>
                          <th className="text-center">Product Id</th>
                          <th className="text-center">Product Name</th>
                          <th className="text-center">Custom Length</th>
                          <th className="text-center">Custom Width</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Price/piece</th>
                          <th className="text-center">Total Price</th>
                          <th className="text-center">Sell Date</th>
                          <th className="text-center">Seller Id</th>
                          <th className="text-center">Seller Name</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sells.map((sell) => (
                          <tr key={sell.sell_id}>
                            <td className="text-secondary text-center">
                              {sell.sell_id}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.customer_name}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.customer_address}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.customer_phone_number}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.product_id &&
                              typeof sell.product_id === "string"
                                ? JSON.parse(sell.product_id).map(
                                    (productId, index) => (
                                      <div key={index}>
                                        Item {index + 1}: {productId}
                                      </div>
                                    )
                                  )
                                : sell.product_id &&
                                  sell.product_id.map((productId, index) => (
                                    <div key={index}>
                                      Item {index + 1}: {productId}
                                    </div>
                                  ))}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.product_name &&
                              typeof sell.product_name === "string"
                                ? JSON.parse(sell.product_name).map(
                                    (productName, index) => (
                                      <div key={index}>
                                        Item {index + 1}: {productName}
                                      </div>
                                    )
                                  )
                                : sell.product_name &&
                                  sell.product_name.map(
                                    (productName, index) => (
                                      <div key={index}>
                                        Item {index + 1}: {productName}
                                      </div>
                                    )
                                  )}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.custom_length &&
                              typeof sell.custom_length === "string"
                                ? JSON.parse(sell.custom_length).map(
                                    (customLength, index) => (
                                      <div key={index}>
                                        Item {index + 1}: {customLength}
                                      </div>
                                    )
                                  )
                                : sell.custom_length &&
                                  sell.custom_length.length
                                ? sell.custom_length.map(
                                    (customLength, index) => (
                                      <div key={index}>
                                        Item {index + 1}: {customLength}
                                      </div>
                                    )
                                  )
                                : "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.custom_width &&
                              typeof sell.custom_width === "string"
                                ? JSON.parse(sell.custom_width).map(
                                    (customWidth, index) => (
                                      <div key={index}>
                                        Item {index + 1}: {customWidth}
                                      </div>
                                    )
                                  )
                                : sell.custom_width && sell.custom_width.length
                                ? sell.custom_width.map(
                                    (customWidth, index) => (
                                      <div key={index}>
                                        Item {index + 1}: {customWidth}
                                      </div>
                                    )
                                  )
                                : "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.quantity &&
                              typeof sell.quantity === "string"
                                ? JSON.parse(sell.quantity).map(
                                    (quantity, index) => (
                                      <div key={index}>
                                        Item {index + 1} quantity: {quantity}
                                      </div>
                                    )
                                  )
                                : sell.quantity &&
                                  sell.quantity.map((quantity, index) => (
                                    <div key={index}>
                                      Item {index + 1} quantity: {quantity}
                                    </div>
                                  ))}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.price && typeof sell.price === "string"
                                ? JSON.parse(sell.price).map((price, index) => (
                                    <div key={index}>
                                      Item {index + 1} price: {price}
                                    </div>
                                  ))
                                : sell.price &&
                                  sell.price.map((price, index) => (
                                    <div key={index}>
                                      Item {index + 1} price: {price}
                                    </div>
                                  ))}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.total_price}
                            </td>
                            <td className="text-secondary text-center">
                              {new Date(sell.createdAt).toLocaleDateString()}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.seller_id}
                            </td>
                            <td className="text-secondary text-center">
                              {sell.seller_name}
                            </td>
                            {/* <td className="text-secondary text-center">
                                {users.find(
                                  (user) => user.user_id === sell.seller_id
                                )?.username || "Unknown"}
                              </td> */}

                            <td className="text-secondary text-center">
                              <div>
                                <button
                                  className="btn btn-primary btn-sm mr-2"
                                  onClick={() => handleEdit(sell)}
                                  title="Edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleDelete(sell.sell_id)}
                                  title="Delete"
                                >
                                  <i className="fas fa-trash-alt mr-1"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* <div className="text-center mt-4">
                        <h5>
                          Total Sell Amount: $
                          {calculateTotalSellAmount().toFixed(2)}
                        </h5>
                      </div> */}
                      {/* <nav aria-label="Pagination" className="mt-3">
                        <ul className="pagination justify-content-center">
                          {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                              <button
                                className={`page-link ${
                                  currentPage === number ? "active" : ""
                                }`}
                                onClick={() => paginate(number)}
                              >
                                {number}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </nav> */}
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateModal
        sell={sellToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />
      <DeleteConfirmationModal
        sellIdToDelete={sellIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default SellIndexGet;
