import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";

import { Link } from "react-router-dom"; // Import Link
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import axios from "../../api/axios";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { TransferDetailsModal } from "./TransferDetailsModal"; // Import the SellDetailsModal component
import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";

const getStatusBadge = (status) => {
  let badgeClass = "";
  switch (status) {
    case "Pending":
      badgeClass = "bg-warning";
      break;
    case "Accepted":
      badgeClass = "bg-success";
      break;
    case "Rejected":
      badgeClass = "bg-danger";
      break;
    default:
      badgeClass = "bg-info";
  }
  return <span className={`badge ${badgeClass}`}>{status}</span>;
};

export const TransferIndexAdd = ({ loggedInUser }) => {
  const [formData, setFormData] = useState({
    transfer_id: "",
    store_manager_id: "",
    // gatepass: "",
    transfer_location: "",
    sell_id: [],
    truck_number: "",
    // status: ""
  });

  const [transferId, setTransferId] = useState(""); // Assuming you have a way to obtain the seller ID
  const [lastFiveTransfers, setLastFiveTransfers] = useState([]);

  const [selectedSell, setSelectedSell] = useState(null); // Change to object

  const [sells, setSells] = useState([]);
  const [loading, setLoading] = useState(false);

  const [transferToShow, setTranserToShow] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);

  const fetchLastFiveTransfers = async () => {
    try {
      const response = await axios.get("/get-transfer?page=1&itemsPerPage=5");
      setLastFiveTransfers(response.data.transferList); // Reverse to display latest entries first
    } catch (error) {
      console.error("Error fetching last five sells:", error);
    }
  };
  const { user } = useContext(AuthContext);

  useEffect(() => {
    // Fetch last 5 categories

    fetchLastFiveTransfers();
    fetchSells();
  }, []);
  // if(user){
  //   const access = user.user?.role.find(role => role.name === "transfer")
  //   if (!access.allow){
  //       return <Error403/>;
  //   }
  // }

  useEffect(() => {
    if (user) {
      const access = user.user?.role.find((role) => role.name === "transfer");
      const modifyaccess = user.user?.role.find(
        (role) => role.name === "modifyTransfer"
      );

      setModifyAccess(modifyaccess ? modifyaccess.allow : false);
      if (!access.allow) {
        return <Error403 />;
      }
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchSells = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-month-sell");
      setSells(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/add-transfer", {
        ...formData,
        store_manager_id: loggedInUser, // Assign logged in user's ID as seller_id
        // sell_id: selectedSell.value,
        sell_id: selectedSell.map((sell) => sell.value),
      });
      console.log("Transfer request added successfully:", response.data);
      toast.success("Transfer request added successfully");
      fetchLastFiveTransfers();
      setFormData({
        transfer_id: "",
        store_manager_id: "",
        gatepass: "",
        transfer_location: "",
        sell_id: [],
        truck_number: "",
        // status: ""
      });
    } catch (error) {
      console.error("Error adding transfer request:", error);
      toast.error("Failed to add transfer request");
    }
  };

  const handleDetails = (transfer) => {
    setTranserToShow(transfer);
    setShowDetailsModal(true);
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              {/* here add a button to redirect to add category page */}

              {modifyAccess && (
                <div className="">
                  <Link to="/transfer" className="btn btn-primary float-right">
                    See All Transfer Request
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Request Transfer</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="transfer_location">
                        Transfer Location
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Dhaka"
                        id="transfer_location"
                        name="transfer_location"
                        value={formData.transfer_location}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="sell_id">Sell Id</label>
                      <Select
                        options={sells.map((sell) => ({
                          value: sell._id,
                          label: sell.sell_id,
                        }))}
                        value={selectedSell}
                        onChange={setSelectedSell}
                        isMulti
                        placeholder="Select Sell Ids"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="transfer_location">Truck Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Dhaka metro-Gha 0245"
                        id="truck_number"
                        name="truck_number"
                        value={formData.truck_number}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Request Transfer
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="card last-five-list-card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Latest Five Transfer Request</h3>
                </div>
                <div className="card-body table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">Transfer ID</th>
                        <th className="text-center">Store Manager Id</th>
                        <th className="text-center">Gatepass</th>
                        <th className="text-center">Transfer Location</th>
                        <th className="text-center">Sell Id</th>
                        <th className="text-center">Request Date</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveTransfers.map((transfer) => (
                        <tr key={transfer.transfer_id}>
                          <td className="text-secondary text-center">
                            {transfer.transfer_id}
                          </td>
                          <td className="text-secondary text-center">
                            {transfer.store_manager_id}
                          </td>
                          <td className="text-secondary text-center">
                            {getStatusBadge(transfer.gatepass)}
                          </td>

                          <td className="text-secondary text-center">
                            {transfer.transfer_location}
                          </td>
                          <td className="text-secondary text-center">
                            {(() => {
                              let sellIdList = [];

                              // Try parsing the sell_id field if it's a string
                              try {
                                sellIdList =
                                  typeof transfer.sell_id === "string"
                                    ? JSON.parse(transfer.sell_id)
                                    : transfer.sell_id;
                              } catch (e) {
                                console.error("Error parsing sell_id", e);
                              }

                              // If sellIdList is an array, map over it
                              return Array.isArray(sellIdList) ? (
                                sellIdList.map((x, i) => (
                                  <p style={{ whiteSpace: "nowrap" }} key={i}>
                                    {x}
                                  </p>
                                ))
                              ) : (
                                <p>{transfer.sell_id}</p>
                              );
                            })()}
                          </td>

                          <td className="text-secondary text-center">
                            {new Date(transfer.createdAt).toLocaleDateString()}
                          </td>
                          <td className="text-secondary text-center">
                            {getStatusBadge(transfer.status)}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm mr-2"
                              onClick={() => handleDetails(transfer)}
                              title="More"
                            >
                              <i className="fas fa-bars mr-1"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TransferDetailsModal
        isOpen={showDetailsModal}
        onRequestClose={() => setShowDetailsModal(false)}
        transferDetails={transferToShow}
      />
    </div>
  );
};

export const TransferIndexGet = () => {
  const [transfers, setTransfers] = useState([]);
  const [transferToUpdate, setTransferToUpdate] = useState(null);
  const [transferStatusToUpdate, setTransferStatusToUpdate] = useState(null);
  const [transferIdToDelete, setTransferIdToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [loading, setLoading] = useState(false);
  const [userLists, setUserLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { user } = useContext(AuthContext);
  const table = useRef(null);

  const itemsPerPage = 15;

  const fetchTransfers = async () => {
    try {
      setTransfers([]);
      const response = await axios
        .get(`/get-transfer?page=${currentPage}&itemsPerPage=${itemsPerPage}`)
        .then((response) => response.data);
      setTransfers(response?.transferList || []);
      setTotalItems(response?.pagination?.totalItems || 0);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchUserlists = async () => {
    try {
      const response = await axios.get("/get-user");
      setUserLists(response.data);
    } catch (error) {
      console.error("Error fetching User Lists:", error);
    }
  };

  useEffect(() => {
    fetchTransfers();
    fetchUserlists();
  }, []);

  if (user) {
    const access = user.user?.role.find(
      (role) => role.name === "modifyTransfer"
    );
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (transfer_id) => {
    setTransferIdToDelete(transfer_id);
    setShowDeleteModal(true);
  };

  const handleEdit = (transfer) => {
    if (transfer.status === "Pending") {
      setTransferToUpdate(transfer);
      setShowUpdateModal(true);
    } else {
      toast.error("Status is not pending.");
    }
  };

  const handleStatusUpdate = async (transferId, status) => {
    if (status === "Accepted" || status === "Rejected") {
      try {
        const response = await axios.put("/update-status", {
          transfer_id: transferId,
          status: status,
        });
        console.log("Transfer status updated successfully:", response.data);
        fetchTransfers();
        toast.success("Transfer status updated successfully");
      } catch (error) {
        console.error("Error updating transfer status:", error);
        toast.error("Failed to update transfer status");
      }
    } else {
      toast.error("Status Already update can not change status");
    }
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await axios.put("/update-transfer", {
        data: transferToUpdate,
      });

      if (response.status === 200) {
        console.log("Transfer request updated successfully:", response.data);
        fetchTransfers();

        toast.success("Transfer request updated successfully");
        setShowUpdateModal(false);
      }
    } catch (error) {
      console.error("Error updating sell:", error);
      toast.error("Failed to update sell");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("delete-transfer", {
        data: { transfer_id: transferIdToDelete },
      });
      console.log("Transfer request deleted successfully:", response.data);
      fetchTransfers();
      toast.success("Transfer request deleted successfully");
    } catch (error) {
      console.error("Error deleting transfer:", error);
      toast.error("Failed to delete transfer");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredTransferLists = transfers.filter((transfer) => {
    const {
      transfer_date = transfer.createdAt,
      transfer_id,
      store_manager_id,
      gatepass,
      transfer_location,
      sell_id,
      status,
    } = transfer;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = transfer_id ? transfer_id.toString() : "";
    const storeManager = store_manager_id
      ? store_manager_id.toString().toLowerCase()
      : "";
    const pass = gatepass ? gatepass.toString().toLowerCase() : "";
    const address = transfer_location
      ? transfer_location.toString().toLowerCase()
      : "";
    const sellId = sell_id ? sell_id.toString().toLowerCase() : "";
    const transferStatus = status ? status.toString().toLowerCase() : "";
    const transferDate = transfer_date
      ? transfer_date.toString().toLowerCase()
      : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      storeManager.includes(searchTerms) ||
      pass.includes(searchTerms) ||
      address.includes(searchTerms) ||
      sellId.includes(searchTerms) ||
      transferStatus.includes(searchTerms) ||
      transferDate.includes(searchTerm)
    );
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    <Link
                      to="/transfer/add"
                      className="btn btn-primary float-right"
                    >
                      Add Transfer Request
                    </Link>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Transfer Request List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive">
                  {transfers.length > 0 && (
                    <DataTable
                      data={filteredTransferLists}
                      ref={table}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Transfer ID</th>
                          <th className="text-center">Store Manager Id</th>
                          <th className="text-center">Gatepass</th>
                          <th className="text-center">Transfer Location</th>
                          <th className="text-center">Sell Id</th>
                          <th className="text-center">Truck Number</th>
                          <th className="text-center">Request Date</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                          <th className="text-center">Status Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTransferLists.map((transfer) => (
                          <tr key={transfer.transfer_id}>
                            <td className="text-secondary text-center">
                              {transfer._id}
                            </td>
                            <td className="text-secondary text-center">
                              {transfer.store_manager_id}
                              {/* Uncomment to display username */}
                              {/* {userLists.find(
                                (users) =>
                                  users.user_id === transfer.store_manager_id
                              )?.username || "Unknown"} */}
                            </td>
                            <td className="text-secondary text-center">
                              {getStatusBadge(transfer.gatepass) || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {transfer.transfer_location}
                            </td>
                            <td className="text-secondary text-center">
                              {(() => {
                                let sellIdList = [];

                                // Try parsing the sell_id field if it's a string
                                try {
                                  sellIdList =
                                    typeof transfer.sell_id === "string"
                                      ? JSON.parse(transfer.sell_id)
                                      : transfer.sell_id;
                                } catch (e) {
                                  console.error("Error parsing sell_id", e);
                                }

                                // If sellIdList is an array, map over it
                                return Array.isArray(sellIdList) ? (
                                  sellIdList.map((x, i) => (
                                    <p style={{ whiteSpace: "nowrap" }} key={i}>
                                      {x}
                                    </p>
                                  ))
                                ) : (
                                  <p>{transfer.sell_id}</p>
                                );
                              })()}
                            </td>

                            <td className="text-secondary text-center">
                              {transfer.truck_number || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {new Date(transfer.createdAt).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>
                            <td className="text-secondary text-center">
                              {getStatusBadge(transfer.status)}
                            </td>
                            <td className="text-secondary text-center">
                              <button
                                className="btn btn-primary btn-sm mr-2"
                                title="Edit"
                                onClick={() => handleEdit(transfer)}
                              >
                                <i className="fas fa-edit mr-1"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() =>
                                  handleDelete(transfer.transfer_id)
                                }
                              >
                                <i className="fas fa-trash-alt mr-1"></i>
                              </button>
                            </td>
                            <td className="text-secondary text-center">
                              <button
                                className="btn btn-success btn-sm mr-2"
                                title="Accept"
                                onClick={() =>
                                  handleStatusUpdate(
                                    transfer.transfer_id,
                                    "Accepted"
                                  )
                                }
                              >
                                <i className="fas fa-check mr-1"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                title="Reject"
                                onClick={() =>
                                  handleStatusUpdate(
                                    transfer.transfer_id,
                                    "Rejected"
                                  )
                                }
                              >
                                <i className="fas fa-times mr-1"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateModal
        transfer={transferToUpdate}
        storeManagerId={
          transferToUpdate ? transferToUpdate.store_manager_id : ""
        }
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />

      <DeleteConfirmationModal
        transferIdToDelete={transferIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};
