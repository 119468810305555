import React, { useState, useEffect } from "react";
import axios from "../../api/axios";

const TopSellingItemsRecentMonth = () => {
  const [topItems, setTopItems] = useState([]);

  useEffect(() => {
    fetchTopItems();
  }, []);

  const fetchTopItems = async () => {
    try {
      const response = await axios.get("/get-top-sell");
      setTopItems(response.data);
    } catch (error) {
      console.error("Error fetching top selling items:", error);
    }
  };

  const convertToSquareMeters = (sqmm) => {
    if (!sqmm || isNaN(sqmm)) {
      return "N/A"; // Return "N/A" if the value is null, undefined, or NaN
    }
    return (sqmm / 1000000).toFixed(2); // Convert sqmm to sqm and round to 2 decimal places
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">Top Selling Items</div>
            <div className="card-body">
              <ul className="list-group">
                {topItems.length > 0
                  ? topItems.map((item, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <span className="font-weight-bold">
                            <strong>{item.product_name}</strong> (ID:{" "}
                            {item.inventory_id})
                          </span>
                          <br />
                          <span>
                            Total Area Sold:{" "}
                            {convertToSquareMeters(item.total_area_sell)} sqm
                          </span>
                        </div>
                      </li>
                    ))
                  : "No data available"}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSellingItemsRecentMonth;
