import React, { useEffect } from "react";
// import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Import AdminLTE CSS
// import 'admin-lte/dist/css/adminlte.min.css';

export const HomeIndex = () => {
  // useEffect(() => {
  //   // Three.js scene setup
  //   const scene = new THREE.Scene();
  //   const camera = new THREE.PerspectiveCamera(
  //     75,
  //     window.innerWidth / window.innerHeight,
  //     0.1,
  //     1000
  //   );
  //   const renderer = new THREE.WebGLRenderer();
  //   renderer.setSize(window.innerWidth, window.innerHeight);
  //   renderer.setClearColor(0xffffff); // Set background color to white
  //   document.body.appendChild(renderer.domElement);

  //   // Add some 3D objects
  //   const geometry = new THREE.BoxGeometry();
  //   const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  //   const cube = new THREE.Mesh(geometry, material);
  //   scene.add(cube);

  //   // Add a sphere
  //   const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
  //   const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
  //   const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
  //   sphere.position.x = -3; // Position it to the left of the cube
  //   scene.add(sphere);

  //   // Add a cylinder
  //   const cylinderGeometry = new THREE.CylinderGeometry(0.5, 0.5, 2, 32);
  //   const cylinderMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
  //   const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);
  //   cylinder.position.x = 3; // Position it to the right of the cube
  //   scene.add(cylinder);

  //   // Camera controls
  //   const controls = new OrbitControls(camera, renderer.domElement);
  //   camera.position.z = 5;

  //   // Animation loop
  //   const animate = function () {
  //     requestAnimationFrame(animate);
  //     cube.rotation.x += 0.01;
  //     cube.rotation.y += 0.01;
  //     sphere.rotation.x += 0.01;
  //     cylinder.rotation.y += 0.01;
  //     renderer.render(scene, camera);
  //   };

  //   animate();

  //   return () => {
  //     // Cleanup
  //     controls.dispose();
  //     renderer.dispose();
  //   };
  // }, []);

  return (
    <div className="wrapper">
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper" style={{ backgroundColor: "#ffffff" }}>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            {/* Your Three.js canvas */}
            <canvas id="threeCanvas"></canvas>
          </div>
        </section>
      </div>
    </div>
  );
};

export const HomeIndexGet = () => {
  return <div>HomeIndex</div>;
};
