import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const MinimumTopThreeWasteModal = ({ show, onClose, topThreeInventories }) => {
    console.log("Top Three Inventories:", topThreeInventories);


  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Inventories with Minimum Waste</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        {topThreeInventories.length === 0 ? (
          <div>No inventories found.</div>
          
        ) : (
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th className="text-center">Inventory Id</th>
                <th className="text-center">Product Name</th>
                <th className="text-center">Initial Length</th>
                <th className="text-center">Initial Width</th>
                <th className="text-center">Waste Length</th>
                <th className="text-center">Waste Width</th>
              </tr>
            </thead>
            <tbody>
              {topThreeInventories.map((inventory) => (
                <tr key={inventory.inventory_id}>
                  <td className="text-secondary text-center">{inventory.inventory_id}</td>
                  <td className="text-secondary text-center">{inventory.product_name}</td>
                  <td className="text-secondary text-center">{inventory.init_slab_length} mm</td>
                  <td className="text-secondary text-center">{inventory.init_slab_weidth} mm</td>
                  <td className="text-secondary text-center">{inventory.deductedLength} mm</td>
                  <td className="text-secondary text-center">{inventory.deductedWidth} mm</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal.Body>
      <Modal.Footer>
        
      </Modal.Footer>
    </Modal>
  );
};

export default MinimumTopThreeWasteModal;
