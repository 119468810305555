import React from "react";

export const UnfavListAdd = () => {
  return <h1>This page is under development</h1>;
};

export const UnfavListGet = () => {
  return (
    <div>
      <h1>This page is alsmost done. Stay tuned!!!</h1>
    </div>
  );
};
