import React, { useContext } from "react";
import "./Invoice.css";
import companyLogo from "./img/emgLogo-1.png"; // Adjust the path as necessary
import { SettingsContext } from "../context/settings";
import ImageLoader from "../components/ImageLoader";

export const Transfer = ({ data }) => {
  const settings = useContext(SettingsContext);

  return (
    <>
      <style>
        {`.invoice-container {
    width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .invoice-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 2px solid #000;
  }
  
  .company-logo {
    width: 100px;
    height: 100px;
  }
  
  .company-info {
    flex-grow: 1;
    text-align: center;
  }
  
  .company-name {
    font-size: 24px;
    font-weight: bold;
  }
  
  .company-address {
    margin-top: 0;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    min-height: 500px;
    display: block;
  }
  
  .invoice-table th,
  .invoice-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }
  
  .thin-column {
    width: 50px;
  }
  
  .invoice-footer {
    margin-top: 30px;
  }
  
  .signature-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 20px;
  }
  
  .signature {
    text-align: center;
    width: 30%;
  }
    .signature p {
    border-top: 1px solid #000;
    line-height: 26px;
    font-size: 13px;
  }
  
  .company-policy {
    text-align: center;
    width: 100%;
    border-top: 2px solid #000;
    margin-bottom: 60px;

  }`}
      </style>
      <div className="invoice-container">
        <div className="invoice-header">
          {/* <img src={companyLogo} alt="Company Logo" className="company-logo" /> */}
          <span className="brand-text fw-bold">
            <img
              src={
                settings.application_logo
                  ? `${process.env.REACT_APP_API_ENDPOINT}/api/uploads/${settings.application_logo}`
                  : "default-logo.png"
              }
              alt="Company Logo"
              className="company-logo"
            />
          </span>
          <div className="company-info">
            <span className="brand-text fw-bold">
              {settings.application_name || "ERP"}
            </span>
            <p className="company-address text-secondary">
              <span className="brand-text fw-bold">
                {settings.application_address || "ERP"}
              </span>{" "}
            </p>
            <p className="company-address text-secondary">Delivery Chalan</p>
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th className="text-secondary">Transfer Id</th>
              <th className="text-secondary">Store manager id</th>
              <th className="text-secondary">Gate Pass</th>
              <th className="text-secondary">Transfer Location</th>
              <th className="text-secondary">Sell Id</th>
              <th className="text-secondary">Request Date</th>
              <th className="text-secondary">Status</th>
            </tr>
          </thead>
          <tbody className="text-secondary">
            {/* Add your table rows here */}
            <tr>
              <td>{data.transfer_id}</td>
              <td>{data.store_manager_id}</td>
              <td>{data.gatepass}</td>
              <td>{data.transfer_location}</td>
              <td>
                {JSON.parse(data.sell_id)?.map((x, i) => (
                  <p style={{ textWrap: "nowrap" }} key={i}>
                    {x}
                  </p>
                ))}
              </td>
              <td>{new Date(data.createdAt).toLocaleDateString()}</td>

              <td>{data.status}</td>
            </tr>
            {/* More rows as needed */}
          </tbody>
        </table>

        <div className="invoice-footer text-secondary">
          <div className="company-policy">
            <p>
              50% payment at the time of order, 100% payment on delivery.
              Marbles & granites are the product of nature, subject to variation
              & vains are accepted. Goods are dispatched at beyons risk & cost
            </p>
          </div>
          <div className="signature-container">
            <div className="signature mt-5">
              <p>Customer's Signature</p>
            </div>

            <div className="signature mt-5">
              <p>Company Signature</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
