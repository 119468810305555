import React from 'react'

export const BlockIndexAdd = () => {
  return (
    <div>        
        <h1>Block Add is under development</h1>
    </div>
  )
}

export const BlockIndexGet = () => {
    return (
      <div>        
        <h1>Block List is under development</h1>
      </div>
    )
  }
