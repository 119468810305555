import { useEffect, useRef, useState } from "react";
import SummernoteLite from "../../lib/summernote/index";
import "../../lib/summernote/dist/summernote-lite.min.css";

const config = [
  ["style", ["style"]],
  [
    "font",
    ["bold", "underline", "clear", "strikethrough", "superscript", "subscript"],
  ],
  ["fontsize", ["fontsize"]],
  ["fontname", ["fontname"]],
  ["color", ["color"]],
  ["para", ["ul", "ol", "paragraph"]],
  ["table", ["table"]],
  ["insert", ["link", "picture", "video", "hr"]],
  ["view", ["codeview", "help"]],
];

const fonts = [
  "Arial",
  "Brush Script MT",
  "Calibri",
  "Candara",
  "Century Gothic",
  "Consolas",
  "Dejavu Sans",
  "Franklin Gothic",
  "Gill Sans",
  "Geneva",
  "Georgia",
  "Garamond",
  "Helvetica",
  "Lucida Sans",
  "MS Sans Serif",
  "Neue Helvetica",
  "Optima",
  "Segoe UI",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

export default function Editor({ label, defaultValue, name }) {
  const [data, setData] = useState("");
  useEffect(() => {
    if (defaultValue) {
      setData(defaultValue);
    }
  }, [defaultValue]);
  return (
    <>
      <div className="mb-3">
        <label className="form-label">{label}</label>
        <SummernoteLite
          defaultCodeValue={defaultValue || ""}
          tabsize={2}
          lang="zh-CN"
          height={350 || "50vh"}
          blockquoteBreakingLevel={0}
          toolbar={config}
          useDiv
          fontNames={fonts}
          //@ts-ignore
          callbacks={{
            //@ts-ignore
            onChange: function (content) {
              setData(content);
            },
          }}
        />
      </div>
      <input type="hidden" name={name} value={data} />
    </>
  );
}
