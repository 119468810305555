import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";

import { Link } from "react-router-dom"; // Import Link
import UpdateModal from "./UpdateModal";
import axios from "../../../api/axios";
import { AuthContext } from "../../../context/auth";
import Error403 from "../../../components/Error/403";
import DataTable from "../../../components/table";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

export const AccountCategoryAdd = () => {
  const [formData, setFormData] = useState({
    categoryId: "",
    categoryName: "",
    subCategoryList: [], // Adding subCategoryList to formData
  });

  const [newSubCategory, setNewSubCategory] = useState(""); // State to manage the new subcategory input

  const [lastFiveCategories, setLastFiveCategories] = useState([]);

  const fetchLastFiveCategories = async () => {
    try {
      const response = await axios.get("/get-all-account-category");
      setLastFiveCategories(response.data?.slice(-5).reverse() || []); // Reverse to display latest entries first
    } catch (error) {
      console.error("Error fetching last five categories:", error);
    }
  };
  const { user } = useContext(AuthContext);

  useEffect(() => {
    // Fetch last 5 categories

    fetchLastFiveCategories();
  }, []);

  if (user) {
    const access = user.user?.role.find(
      (role) => role.name === "accountCategory"
    );
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubCategoryChange = (e) => {
    setNewSubCategory(e.target.value);
  };

  const handleAddSubCategory = (e) => {
    e.preventDefault();
    if (newSubCategory.trim()) {
      setFormData({
        ...formData,
        subCategoryList: [
          ...formData.subCategoryList,
          { name: newSubCategory },
        ],
      });
      setNewSubCategory(""); // Clear input field after adding
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/add-account-category", formData);
      toast.success("Category added successfully");
      fetchLastFiveCategories();
      setFormData({ categoryId: "", categoryName: "", subCategoryList: [] }); // Clear input fields after successful submission
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category");
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              <Link
                to="/account/category"
                className="btn btn-primary float-right"
              >
                See All Category
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Category</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="categoryName">Category Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Marble "
                        id="categoryName"
                        name="categoryName"
                        value={formData.categoryName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    {/* Subcategory Input Section */}
                    <div className="form-group">
                      <label htmlFor="subCategory">
                        Add Subcategories (if any)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Subcategory Name"
                        value={newSubCategory}
                        onChange={handleSubCategoryChange}
                      />
                      <button
                        className="btn btn-primary mt-2"
                        onClick={handleAddSubCategory}
                      >
                        +
                      </button>
                    </div>
                    {/* Display Added Subcategories */}
                    {formData.subCategoryList.length > 0 && (
                      <div className="form-group">
                        <label>Added Subcategories:</label>
                        <ul>
                          {formData.subCategoryList.map(
                            (subCategory, index) => (
                              <li key={index}>{subCategory.name}</li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                    <button type="submit" className="btn btn-primary">
                      Add Category
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Latest Five Categories</h3>
                </div>
                <div className="card-body">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th className="text-center">Category ID</th>
                        <th className="text-center">Category Name</th>
                        <th className="text-center">Subcategories</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveCategories.map((category) => (
                        <tr key={category.categoryId}>
                          <td className="text-secondary text-center">
                            {category.categoryId}
                          </td>
                          <td className="text-secondary text-center">
                            {category.categoryName}
                          </td>
                          <td className="text-secondary text-center">
                            {category.subCategoryList
                              .map((sub) => sub.name)
                              .join(", ") || "N/A"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const AccountCategoryGet = () => {
  const formatPageName = (path) => {
    return (
      path.substring(1).replace("/", " ").charAt(0).toUpperCase() +
      path.substring(2).replace("/", " ")
    );
  };

  const [categories, setCategoryLists] = useState([]);
  const { user } = useContext(AuthContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 15;

  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [categoryToUpdate, setCategoryToUpdate] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const fetchCategorylists = async () => {
    try {
      const response = await axios.get(
        `/get-account-category?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setCategoryLists(response?.data?.categoryList || []);
      setTotalItems(response?.data?.pagination?.totalItems || 0);
    } catch (error) {
      console.error("Error fetching category lists:", error);
    }
  };

  useEffect(() => {
    fetchCategorylists();
  }, [currentPage]);

  if (user) {
    const access = user.user?.role.find(
      (role) => role.name === "accountCategory"
    );
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (categoryId) => {
    setCategoryIdToDelete(categoryId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("/delete-account-category", {
        data: { categoryId: categoryIdToDelete },
      });
      toast.success("Category deleted successfully");
      fetchCategorylists();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Failed to delete category");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleEdit = (category) => {
    setCategoryToUpdate(category);
    setShowUpdateModal(true);
  };

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded table-responsive">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link
                      to="/account/category/add"
                      className="btn btn-primary float-right mr-2"
                    >
                      Add Category
                    </Link>
                  </div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">All Category List</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {categories.length > 0 && (
                    <DataTable
                      data={categories}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Category Id</th>
                          <th className="text-center">Category Name</th>
                          <th className="text-center">Sub-category Name</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categories.map((category) => (
                          <tr key={category.categoryId}>
                            <td className="text-secondary text-center">
                              {category.categoryId}
                            </td>
                            <td className="text-secondary text-center">
                              {category.categoryName}
                            </td>
                            <td className="text-secondary text-center">
                              {category.subCategoryList
                                .map((sub) => sub.name)
                                .join(", ") || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary btn-sm mr-2"
                                  onClick={() => handleEdit(category)}
                                  title="Edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm ml-2"
                                  onClick={() =>
                                    handleDelete(category.categoryId)
                                  }
                                  title="Delete"
                                >
                                  <i className="fas fa-trash-alt mr-1"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DeleteConfirmationModal
        categoryIdToDelete={categoryIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />

      <UpdateModal
        category={categoryToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
      />
    </div>
  );
};
