import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
import Select from "react-select";
import { AuthContext } from "../../../context/auth";
import Error403 from "../../../components/Error/403";

export const OthersAccountIndexAdd = ({ loggedInUser }) => {
  const [formData, setFormData] = useState({
    recordName: "",
    recordType: "",
    paymentMethod: "",
    paymentDate: "",
    amount: "",
    status: "",
    note: "",
  });

  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch initial data if needed
  }, []);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "accountOther");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/add-other-account", {
        ...formData,
        user_id: loggedInUser,
      });
      toast.success("Account added successfully");

      // Reset form after submission
      setFormData({
        recordName: "",
        recordType: "",
        paymentMethod: "",
        paymentDate: "",
        amount: "",
        status: "",
        note: "",
      });
    } catch (error) {
      console.error("Error adding account:", error);
      toast.error("Failed to add account");
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="">
              <Link
                to="/account/others"
                className="btn btn-primary float-right"
              >
                See All Records
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Add Other Record</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="recordName">Record Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="recordName"
                            name="recordName"
                            value={formData.recordName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="recordType">Record Type</label>
                          <input
                            type="text"
                            className="form-control"
                            id="recordType"
                            name="recordType"
                            value={formData.recordType}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="paymentMethod">Payment Method</label>
                          <input
                            type="text"
                            className="form-control"
                            id="paymentMethod"
                            name="paymentMethod"
                            value={formData.paymentMethod}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="paymentDate">Payment Date</label>
                          <input
                            type="date"
                            className="form-control"
                            id="paymentDate"
                            name="paymentDate"
                            value={formData.paymentDate}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="amount">Amount</label>
                          <input
                            type="number"
                            className="form-control"
                            id="amount"
                            name="amount"
                            value={formData.amount}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <Select
                            options={[
                              { value: "Pending", label: "Pending" },
                              { value: "Completed", label: "Completed" },
                              { value: "Failed", label: "Failed" },
                            ]}
                            value={{
                              value: formData.status,
                              label: formData.status || "Select Status",
                            }}
                            onChange={(selectedOption) =>
                              setFormData({
                                ...formData,
                                status: selectedOption.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="note">Note</label>
                          <textarea
                            className="form-control"
                            id="note"
                            name="note"
                            value={formData.note}
                            onChange={handleInputChange}
                            rows="3"
                          />
                        </div>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Add Account
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
