import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";

import { Link } from "react-router-dom"; // Import Link
import axios from "../../api/axios";

import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";

const getStatusBadge = (status) => {
  let badgeClass = "";
  switch (status) {
    case "Pending":
      badgeClass = "bg-warning";
      break;
    case "Accepted":
      badgeClass = "bg-success";
      break;
    case "Rejected":
      badgeClass = "bg-danger";
      break;
    default:
      badgeClass = "bg-info";
  }
  return <span className={`badge ${badgeClass}`}>{status}</span>;
};

export const WasteIndexGet = ({ loggedInUser }) => {
  const [wastes, setWate] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [categories, setCategories] = useState([]);
  const { user } = useContext(AuthContext);

  const table = useRef(null);

  const itemsPerPage = 15;

  const fetchWastes = async () => {
    try {
      const response = await axios.get(
        `/get-waste?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setWate(response.data?.wastage || []);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };
  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWastes();
    fetchCategories();
  }, []);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "waste");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleStatusUpdate = async (wasteId, status) => {
    if (status === "Accepted") {
      try {
        const response = await axios.put("/update-waste-status", {
          waste_id: wasteId,
          status: status,
        });
        fetchWastes();
        toast.success("Waste status updated successfully");
      } catch (error) {
        console.error("Error updating waste status:", error);
        toast.error("Failed to update waste status");
      }
    } else if (status === "Rejected") {
      try {
        const response = await axios.put("/update-waste-status", {
          waste_id: wasteId,
          status: status,
          user_id: loggedInUser,
        });
        fetchWastes();
        toast.success("Item added to inventory successfully");
      } catch (error) {
        console.error("Error updating waste status:", error);
        toast.error("Failed to update waste status");
      }
    } else {
      toast.error("Status Already update can not change status");
    }
  };

  const filteredWateLists = wastes.filter((waste) => {
    const {
      waste_date = waste.createdAt,
      waste_id,
      slab_weidth,
      slab_length,
      requisition_id,
      quantity,
      category_name,
      vendor_id,
      status,
    } = waste;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = waste_id ? waste_id.toString() : "";
    const slabWidth = slab_weidth ? slab_weidth.toString() : "";
    const slabLength = slab_length ? slab_length.toString() : "";
    const requisitionId = requisition_id ? requisition_id.toString() : "";
    const wasteQuantity = quantity ? quantity.toString().toLowerCase() : "";
    const wasteStatus = status ? status.toString().toLowerCase() : "";
    const categoryName = category_name
      ? category_name.toString().toLowerCase()
      : "";
    const vendorId = vendor_id ? vendor_id.toString().toLowerCase() : "";
    const wasteDate = waste_date ? waste_date.toString().toLowerCase() : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      slabWidth.includes(searchTerms) ||
      slabLength.includes(searchTerms) ||
      requisitionId.includes(searchTerms) ||
      vendorId.includes(searchTerms) ||
      wasteQuantity.includes(searchTerms) ||
      wasteStatus.includes(searchTerms) ||
      categoryName.includes(searchTerms) ||
      wasteDate.includes(searchTerm)
    );
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    <Link
                      to="/waste/aprroved"
                      className="btn btn-primary float-right"
                    >
                      See Approved
                    </Link>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="">
                    <Link
                      to="/transfer/add"
                      className="btn btn-primary float-right"
                    >
                      Add Transfer Request
                    </Link>
                  </div> */}
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Waste List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive">
                  {wastes.length > 0 && (
                    <DataTable
                      data={wastes}
                      ref={table}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Waste ID</th>
                          <th className="text-center">Product Name</th>
                          <th className="text-center">Width</th>
                          <th className="text-center">Length</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center"> Category Id</th>
                          <th className="text-center">Waste Date</th>
                          <th className="text-center">Vendor Id</th>
                          <th className="text-center">Status</th>
                          {/* <th className="text-center">Action</th> */}
                          <th className="text-center">Status Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredWateLists.map((waste) => (
                          <tr key={waste.waste_id}>
                            <td className="text-secondary text-center">
                              {waste.waste_id}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.product_name}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.slab_weidth}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.slab_length}
                            </td>

                            <td className="text-secondary text-center">
                              {waste.quantity}
                            </td>
                            {/* <td className="text-secondary text-center">
                              {waste.category_name}
                            </td> */}
                            <td className="text-secondary text-center">
                              {categories.find(
                                (category) =>
                                  category.unique_category_id ===
                                  waste.category_name
                              )?.category_name || "Unknown"}
                            </td>

                            {/* <td className="text-secondary text-center"> */}
                            {/* {sell.createdAt}
                              {new Date(
                                transfer.createdAt
                              ).toLocaleDateString()} */}
                            {/* </td> */}
                            <td className="text-secondary text-center">
                              {new Date(waste.createdAt).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>

                            <td className="text-secondary text-center">
                              {waste.vendor_id}
                            </td>

                            <td className="text-secondary text-center">
                              {getStatusBadge(waste.status)}
                            </td>

                            {/* <td className="text-secondary text-center">
                              <button
                                className="btn btn-primary btn-sm mr-2"
                                title="Edit"
                                onClick={() => handleEdit(transfer)}
                              >
                                <i className="fas fa-edit mr-1"></i>
                              </button>

                              <button
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() =>
                                  handleDelete(transfer.transfer_id)
                                }
                              >
                                <i className="fas fa-trash-alt mr-1"></i>
                              </button>
                            </td> */}

                            <td className="text-secondary text-center">
                              <button
                                className="btn btn-success btn-sm mr-2"
                                title="Accept"
                                onClick={() =>
                                  handleStatusUpdate(waste.waste_id, "Accepted")
                                }
                              >
                                <i className="fas fa-check mr-1"></i>
                              </button>

                              <button
                                className="btn btn-danger btn-sm"
                                title="Reject"
                                onClick={() =>
                                  handleStatusUpdate(waste.waste_id, "Rejected")
                                }
                              >
                                <i className="fas fa-times mr-1"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <UpdateModal
        transfer={transferToUpdate}
        storeManagerId={
          transferToUpdate ? transferToUpdate.store_manager_id : ""
        }
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      /> */}

      {/* <DeleteConfirmationModal
        transferIdToDelete={transferIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      /> */}
    </div>
  );
};

export const WasteIndexRead = () => {
  const [wastes, setWastes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useContext(AuthContext);

  const fetchWastes = async () => {
    try {
      const response = await axios
        .get("/get-approved-waste")
        .then((r) => r.data); // Ensure this matches your backend route
      setWastes(response.rows?.reverse() || []);
    } catch (error) {
      console.error("Error fetching wastes:", error);
    }
  };

  useEffect(() => {
    fetchWastes();
  }, []);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "waste");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const filteredWasteLists = wastes.filter((waste) => {
    const {
      waste_date = waste.createdAt,
      waste_id,
      slab_weidth,
      slab_length,
      requisition_id,
      quantity,
      category_name,
      vendor_id,
      status,
    } = waste;

    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = waste_id ? waste_id.toString() : "";
    const slabWidth = slab_weidth ? slab_weidth.toString() : "";
    const slabLength = slab_length ? slab_length.toString() : "";
    const requisitionId = requisition_id ? requisition_id.toString() : "";
    const vendorId = vendor_id ? vendor_id.toString() : "";
    const wasteQuantity = quantity ? quantity.toString().toLowerCase() : "";
    const wasteStatus = status ? status.toString().toLowerCase() : "";
    const categoryName = category_name
      ? category_name.toString().toLowerCase()
      : "";
    const wasteDate = waste_date ? waste_date.toString().toLowerCase() : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      slabWidth.includes(searchTerms) ||
      slabLength.includes(searchTerms) ||
      requisitionId.includes(searchTerms) ||
      wasteQuantity.includes(searchTerms) ||
      wasteStatus.includes(searchTerms) ||
      categoryName.includes(searchTerms) ||
      wasteDate.includes(searchTerm)
    );
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    <Link to="/waste" className="btn btn-primary float-right">
                      Sell All waste
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Waste List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive">
                  {filteredWasteLists.length === 0 ? (
                    <div className="text-center">
                      No item found in the list.
                    </div>
                  ) : (
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Waste ID</th>
                          <th className="text-center">Product Name</th>
                          <th className="text-center">Width</th>
                          <th className="text-center">Length</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Category Name</th>
                          <th className="text-center">Waste Date</th>
                          <th className="text-center">Vendor ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredWasteLists.map((waste) => (
                          <tr key={waste.waste_id}>
                            <td className="text-secondary text-center">
                              {waste.waste_id}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.product_name}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.slab_weidth}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.slab_length}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.quantity}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.category_name}
                            </td>
                            <td className="text-secondary text-center">
                              {new Date(waste.createdAt).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>
                            <td className="text-secondary text-center">
                              {waste.vendor_id}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
