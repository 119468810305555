import React, { useReducer, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";

const initialState = {
  user: null,
};

const AuthContext = createContext({
  user: null,
  login: (userData) => {},
  logout: () => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  async function login(userData) {
    try {
      const encKey = "625ds6ds6g25dsagg256sdg6sgd2";
      const data = JSON.stringify(userData);
      const AES = await import("crypto-js/aes");
      const encData = AES.encrypt(data, encKey);
      const encDataStr = encData.toString();
      localStorage.setItem("x_sid", encDataStr);
      dispatch({
        type: "LOGIN",
        payload: userData,
      });
    } catch (err) {
      console.log(err.message);
    }
  }

  async function logout() {
    try {
      const user_id = sessionStorage.getItem("user_id");
      if (!user_id) throw new Error("User ID not found in session storage");
      await axios.post("/logout", { user_id }).then((e) => e.data);
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      sessionStorage.clear();
      localStorage.removeItem("x_sid");
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    }
  }

  async function getUserDataFromStorage() {
    try {
      const data = localStorage.getItem("x_sid");
      if (data) {
        const CryptoJS = await import("crypto-js");
        const encKey = "625ds6ds6g25dsagg256sdg6sgd2";
        const decrData = CryptoJS.AES.decrypt(data, encKey);
        const decrDataStr = decrData.toString(CryptoJS.enc.Utf8);
        login(JSON.parse(decrDataStr));
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    getUserDataFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: state.user, login, logout }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
