import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const UpdateModal = ({ category, show, onClose }) => {
  const [updatedCategory, setUpdatedCategory] = useState({
    categoryId: "",
    categoryName: "",
    subCategoryList: [],
  });

  const [newSubCategory, setNewSubCategory] = useState(""); // For adding new subcategories

  useEffect(() => {
    if (category) {
      setUpdatedCategory({
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        subCategoryList: category.subCategoryList || [],
      });
    }
  }, [category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCategory({
      ...updatedCategory,
      [name]: value,
    });
  };

  const handleSubCategoryChange = (e) => {
    setNewSubCategory(e.target.value);
  };

  const handleAddSubCategory = () => {
    if (newSubCategory.trim()) {
      setUpdatedCategory({
        ...updatedCategory,
        subCategoryList: [
          ...updatedCategory.subCategoryList,
          { name: newSubCategory },
        ],
      });
      setNewSubCategory(""); // Clear input field after adding
    }
  };

  const handleRemoveSubCategory = (index) => {
    const updatedSubCategoryList = updatedCategory.subCategoryList.filter(
      (sub, subIndex) => subIndex !== index
    );
    setUpdatedCategory({
      ...updatedCategory,
      subCategoryList: updatedSubCategoryList,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        "/update-account-category",
        updatedCategory
      );
      toast.success("Category updated successfully");
      onClose();
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("Failed to update category");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="categoryId">Category ID</label>
            <input
              type="text"
              className="form-control"
              id="categoryId"
              name="categoryId"
              value={updatedCategory.categoryId}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="categoryName">Category Name</label>
            <input
              type="text"
              className="form-control"
              id="categoryName"
              name="categoryName"
              value={updatedCategory.categoryName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Subcategory Input Section */}
          <div className="form-group">
            <label htmlFor="subCategory">Add Subcategories</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Subcategory Name"
              value={newSubCategory}
              onChange={handleSubCategoryChange}
            />
            <button
              className="btn btn-primary mt-2"
              type="button"
              onClick={handleAddSubCategory}
            >
              +
            </button>
          </div>

          {/* Display Added Subcategories */}
          {updatedCategory.subCategoryList.length > 0 && (
            <div className="form-group">
              <label>Added Subcategories:</label>
              <ul>
                {updatedCategory.subCategoryList.map((subCategory, index) => (
                  <li key={index}>
                    {subCategory.name}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      onClick={() => handleRemoveSubCategory(index)}
                    >
                      x
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <Button variant="primary" type="submit">
            Update Category
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
