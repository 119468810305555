import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom"; // Import Link
import axios from "../../../api/axios";
import { AuthContext } from "../../../context/auth";
import Error403 from "../../../components/Error/403";
import DataTable from "../../../components/table";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";

export const ExternalInventoryGet = () => {
  const [inventories, setInventoriesList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [inventoryToDelete, setInventoryToDelete] = useState(null);

  const { user } = useContext(AuthContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [inventoryToUpdate, setInventoryToUpdate] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 15;

  // Fetch data function
  const fetchExternalInventorylists = async () => {
    try {
      const response = await axios.get(
        `/get-account-inventory?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setInventoriesList(response.data.inventories);
      setTotalItems(response.data.pagination.totalItems);
    } catch (error) {
      console.error("Error fetching vendor Lists:", error);
    }
  };

  useEffect(() => {
    fetchExternalInventorylists();
  }, [currentPage]);

  if (user) {
    const access = user.user?.role.find(
      (role) => role.name === "accountInventory"
    );
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (externalinventory_id) => {
    setInventoryToDelete(externalinventory_id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("/delete-account-inventory", {
        data: { externalinventory_id: inventoryToDelete },
      });
      toast.success("Category deleted successfully");
      fetchExternalInventorylists();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Failed to delete category");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleEdit = (inventory) => {
    setInventoryToUpdate(inventory);
    setShowUpdateModal(true);
  };

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded table-responsive">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link
                      to="/account/inventory/add"
                      className="btn btn-primary float-right mr-2"
                    >
                      Add Items
                    </Link>
                  </div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">All Items List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Ensure data is loaded before rendering the table */}
                {inventories.length > 0 ? (
                  <div className="card-body">
                    <DataTable
                      data={inventories}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">External Inventory Id</th>
                          <th className="text-center">Client Name</th>
                          <th className="text-center">Product Name</th>
                          <th className="text-center">Product Width</th>
                          <th className="text-center">Product Length</th>
                          <th className="text-center">Product Height</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inventories.map((inventory) => (
                          <tr key={inventory.externalinventory_id}>
                            <td className="text-secondary text-center">
                              {inventory.externalinventory_id}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.client_name}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.product_name}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.init_product_width}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.init_product_length}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.init_height}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.quantity}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.status || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary btn-sm mr-2"
                                  onClick={() => handleEdit(inventory)}
                                  title="Edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm ml-2"
                                  onClick={() =>
                                    handleDelete(inventory.externalinventory_id)
                                  }
                                  title="Delete"
                                >
                                  <i className="fas fa-trash-alt mr-1"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  </div>
                ) : (
                  <div className="card-body">
                    <p>No data available.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <DeleteConfirmationModal
        inventoryToDelete={setInventoryToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />

      <UpdateModal
        inventory={inventoryToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
      />
    </div>
  );
};
