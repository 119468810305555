import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";

import { Link } from "react-router-dom"; // Import Link
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import routesConfig from "../../routesConfig"; // Adjust the import path accordingly
// import AssignUserModal from "./AssignUserRoleModal";

import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";

export const VendorIndexAdd = () => {
  const formatPageName = (path) => {
    return (
      path.substring(1).replace("/", " ").charAt(0).toUpperCase() +
      path.substring(2).replace("/", " ")
    );
  };

  const [formData, setFormData] = useState({
    vendor_name: "",
    vendor_email: "",
    phone: "",
    store_name: "",
    origin: "",
    address: "",
    zipCode: "",
  });

  const { user } = useContext(AuthContext);
  if (user) {
    const access = user.user?.role.find((role) => role.name === "vendor");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSwitchChange = (page, switchName) => (e) => {
  //   setPageSwitches({
  //     ...pageSwitches,
  //     [page]: {
  //       ...pageSwitches[page],
  //       [switchName]: e.target.checked,
  //     },
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const addressWithZip = formData.zipCode
        ? `${formData.address}, ${formData.zipCode}`
        : formData.address;

      const updatedFormData = {
        ...formData,
        address: addressWithZip,
      };

      const response = await axios.post("/add-vendor", updatedFormData);
      if (response.status === 201) {
        toast.success("Vendor added successful");
        setFormData({
          vendor_name: "",
          vendor_email: "",
          phone: "",
          store_name: "",
          origin: "",
          address: "",
          zipCode: "",
        });
        // setSelectedRoles({
        //   viewer: false,
        //   editor: false,
        // });
      } else {
        toast.error("Failed to add vendor");
      }
    } catch (error) {
      console.error("Error registering:", error);
      toast.error("Failed to register");
    }
  };

  return (
    <div className="container">
      <div className="">
        <div className="card shadow p-3 mb-5 bg-body rounded ">
          <div className="card-header">
            <h3 className="card-title">Add Vendor</h3>
          </div>
          <div className="card-body mb-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="vendor_name"
                  name="vendor_name"
                  value={formData.vendor_name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="vendor_email"
                  id="vendor_email"
                  value={formData.vendor_email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                      className="form-control"
                      id="address"
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      className="form-control"
                      id="zipCode"
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="origin">Country</label>
                    <input
                      className="form-control"
                      id="origin"
                      type="text"
                      name="origin"
                      value={formData.origin}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="store_name">Company Name</label>
                    <input
                      className="form-control"
                      id="store_name"
                      type="text"
                      name="store_name"
                      value={formData.store_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <button type="submit" className="btn btn-primary mt-2">
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const VendorIndexGet = () => {
  const formatPageName = (path) => {
    return (
      path.substring(1).replace("/", " ").charAt(0).toUpperCase() +
      path.substring(2).replace("/", " ")
    );
  };

  const [vendors, setVendorLists] = useState([]);
  const [vendorlistIdToDelete, setVendorlistIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vendorlistIdToUpdate, setVendorlistIdToUpdate] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useContext(AuthContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 15;

  const fetchVendorlists = async () => {
    try {
      const response = await axios.get(
        `/get-vendor?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      const vendorsWithZip = response.data.vendorList.map((vendor) => {
        const addressParts = vendor.address.split(", ");
        const zipCode = addressParts.length > 1 ? addressParts.pop() : "";
        return {
          ...vendor,
          address: addressParts.join(", "),
          zipCode: zipCode,
        };
      });
      setVendorLists(vendorsWithZip);
      setTotalItems(response.data.pagination.totalItems);
    } catch (error) {
      console.error("Error fetching vendor Lists:", error);
    }
  };

  useEffect(() => {
    fetchVendorlists();
  }, [currentPage]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "vendor");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (vendor_id) => {
    setVendorlistIdToDelete(vendor_id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("/delete-vendor", {
        data: { vendor_id: vendorlistIdToDelete },
      });
      console.log("Vendor deleted successfully:", response.data);
      fetchVendorlists();
      toast.success("Vendor deleted successfully");
    } catch (error) {
      console.error("Error deleting vendor:", error);
      toast.error("Failed to delete vendor");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleEdit = (vendor) => {
    setVendorlistIdToUpdate(vendor);
    setShowUpdateModal(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await axios.put("/update-vendor", {
        data: vendorlistIdToUpdate,
      });

      if (response.status === 200) {
        console.log("Vendor updated successfully:", response.data);
        fetchVendorlists();
        toast.success("Vendor updated successfully");
        setShowUpdateModal(false);
      }
    } catch (error) {
      console.error("Error updating vendor:", error);
      toast.error("Failed to update vendor");
    }
  };

  const filteredVendorLists = vendors.filter((vendor) => {
    const {
      vendor_id,
      vendor_name,
      vendor_email,
      phone,
      store_name,
      origin,
      address,
    } = vendor;
    const searchTerms = searchTerm.toLowerCase();

    const id = vendor_id ? vendor_id.toString() : "";
    const name = vendor_name ? vendor_name.toString().toLowerCase() : "";
    const useremail = vendor_email ? vendor_email.toString().toLowerCase() : "";
    const phone_number = phone ? phone.toString().toLowerCase() : "";
    const storeName = store_name ? store_name.toString().toLowerCase() : "";
    const country = origin ? origin.toString().toLowerCase() : "";
    const userAddress = address ? address.toString().toLowerCase() : "";

    return (
      id.includes(searchTerms) ||
      name.includes(searchTerms) ||
      useremail.includes(searchTerms) ||
      phone_number.includes(searchTerms) ||
      storeName.includes(searchTerms) ||
      country.includes(searchTerms) ||
      userAddress.includes(searchTerms)
    );
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded table-responsive">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link
                      to="/vendor/add"
                      className="btn btn-primary float-right mr-2"
                    >
                      Add Vendor
                    </Link>
                  </div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">All Vendor List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {vendors.length > 0 && (
                    <DataTable
                      data={filteredVendorLists}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Vendor Id</th>
                          <th className="text-center">Vendor Name</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Phone Number</th>
                          <th className="text-center">Store Name</th>
                          <th className="text-center">Address</th>
                          <th className="text-center">Origin</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredVendorLists.map((vendor) => (
                          <tr key={vendor.vendor_id}>
                            <td className="text-secondary text-center">
                              {vendor.vendor_id}
                            </td>
                            <td className="text-secondary text-center">
                              {vendor.vendor_name}
                            </td>
                            <td className="text-secondary text-center">
                              {vendor.vendor_email}
                            </td>
                            <td className="text-secondary text-center">
                              {vendor.phone}
                            </td>
                            <td className="text-secondary text-center">
                              {vendor.store_name}
                            </td>
                            <td className="text-secondary text-center">
                              {vendor.address}
                            </td>
                            <td className="text-secondary text-center">
                              {vendor.origin}
                            </td>
                            <td className="text-secondary text-center">
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary btn-sm mr-2"
                                  onClick={() => handleEdit(vendor)}
                                  title="Edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>

                                <button
                                  className="btn btn-danger btn-sm ml-2"
                                  onClick={() => handleDelete(vendor.vendor_id)}
                                >
                                  <i className="fas fa-trash-alt mr-1"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DeleteConfirmationModal
        userlistIdToDelete={setVendorlistIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />

      <UpdateModal
        vendor={vendorlistIdToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />
    </div>
  );
};
