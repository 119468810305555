import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
import Select from "react-select";
import { AuthContext } from "../../../context/auth";
import Error403 from "../../../components/Error/403";

export const ExternalInventoryAdd = ({ loggedInUser }) => {
  const [formData, setFormData] = useState({
    externalinventory_id: "",
    client_name: "",
    product_name: "",
    user_id: "",
    init_product_width: "",
    init_product_length: "",
    init_height: "",
    quantity: "",
    category_id: "",
    status: "",
  });
  const { user } = useContext(AuthContext);

  const [lastFiveInventories, setLastFiveInventories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("Block");
  const [lengthUnit, setLengthUnit] = useState("mm");

  const fetchLastFiveInventories = async () => {
    try {
      const response = await axios.get("/get-all-account-inventory");
      setLastFiveInventories(response.data.slice(-5).reverse());
    } catch (error) {
      console.error("Error fetching last five inventories:", error);
    }
  };

  useEffect(() => {
    fetchLastFiveInventories();
    fetchCategories();
  }, []);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "inventory");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/add-account-inventory", {
        ...formData,
        user_id: loggedInUser,
        category_id: selectedCategory.value,
      });
      toast.success("Item added successfully");
      fetchLastFiveInventories();
      setFormData({
        externalinventory_id: "",
        client_name: "",
        product_name: "",
        init_product_width: "",
        init_product_length: "",
        init_height: "",
        quantity: "",
        status: "",
      });
    } catch (error) {
      console.error("Error adding item:", error);
      toast.error("Failed to add item");
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-all-account-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              <Link
                to="/account/inventory"
                className="btn btn-primary float-right"
              >
                See All Inventory
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Inventory</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="row mb-2">
                        <div className="col-md-3">
                          <label htmlFor="unit">Select Unit</label>
                          <Select
                            options={[
                              { value: "MM", label: "MM" },
                              { value: "Inch", label: "Inch" },
                            ]}
                            value={{ value: lengthUnit, label: lengthUnit }}
                            onChange={(selectedOption) =>
                              setLengthUnit(selectedOption.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="product_name">Product Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ex- Alaxa White Block"
                              id="product_name"
                              name="product_name"
                              value={formData.product_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="init_product_length">
                              Product Length
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`Enter Product Length (${lengthUnit})`}
                              id="init_product_length"
                              name="init_product_length"
                              value={formData.init_product_length}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="init_product_width">
                              Product Width
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`Enter Product Width (${lengthUnit})`}
                              id="init_product_width"
                              name="init_product_width"
                              value={formData.init_product_width}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="init_height">Product Height</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ex- 50 "
                              id="init_height"
                              name="init_height"
                              value={formData.init_height}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="client_name">Client Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ex- Alaxa White Block"
                              id="client_name"
                              name="client_name"
                              value={formData.client_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category_id">Category Name</label>
                          <Select
                            options={categories.map((category) => ({
                              value: category._id,
                              label: category.categoryName,
                            }))}
                            value={selectedCategory}
                            onChange={setSelectedCategory}
                            placeholder="Select Category"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="quantity">Quantity</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 1200"
                            id="quantity"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Add Item
                    </button>
                  </form>
                </div>
                <div className="mt-4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
