import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router
import axios from "../../api/axios";

const NoticeBoard = () => {
  const [lastThreeNotice, setLastThreeNotice] = useState([]);

  const fetchLastThreeNotice = async () => {
    try {
      const response = await axios.get(
        "/get-published-notice?page=1&itemsPerPage=3"
      );

      setLastThreeNotice(response.data?.publishedNotices || []);
    } catch (error) {
      console.error("Error fetching last three notices:", error);
    }
  };

  useEffect(() => {
    fetchLastThreeNotice();
  }, []);

  return (
    <div className="card">
      <div className="card-header py-3 text-center">
        <b>Notice Board</b>
      </div>
      <div className="card-body ">
        <ul className="list-group list-group-flush">
          {lastThreeNotice.map((notice) => (
            <li className="list-group-item" key={notice.id}>
              <h4 style={{ fontSize: "16px" }}>{notice.notice_title}</h4>
              {/* <div dangerouslySetInnerHTML={{ __html: notice.notice_description }} /> */}
            </li>
          ))}
        </ul>
        <div className="text-center mt-3">
          <Link to="/notice/all" className="btn btn-primary">
            See All Notices
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;
