import React, { useReducer, createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/axios";

const initialState = {
  application_name: "",
  application_logo: "",
  application_address: "",
  application_color: "",
  application_color_contrast: "",
  application_color_gradient: "",
};

const SettingsContext = createContext({
  ...initialState,
  updateSettings: (data) => {},
});

function settingsReducer(state, action) {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

function SettingsProvider(props) {
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(settingsReducer, initialState);
  async function getSettings() {
    try {
      const response = await axios.get("/get-settings");
      if (response.data && response.data[0]) {
        const {
          application_name,
          application_logo,
          application_address,
          application_color,
          application_color_contrast,
          application_color_gradient,
        } = response.data[0];
        const data = {
          application_name,
          application_logo,
          application_address,
          application_color,
          application_color_contrast,
          application_color_gradient,
        };
        dispatch({
          type: "UPDATE",
          payload: data,
        });
      } else {
        toast.error("Unable to load");
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <div className={`loader ${loading ? "show-l" : "hide-l"}`}>
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="70px"
          height="70px"
          viewBox="0 0 50 50"
          style={{ enableBackground: "new 0 0 50 50" }}
        >
          <path
            fill="#000"
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
      <SettingsContext.Provider value={{ ...state }} {...props} />
    </>
  );
}

export { SettingsContext, SettingsProvider };
