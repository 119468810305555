import React, { useState, useEffect, useContext } from "react";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import "../style.css";
import FileUpload from "../../components/fileUpload/FileUpload";
import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import ImageLoader from "../../components/ImageLoader";
import { toast } from "react-toastify";

export const ProfileIndex = ({ loggedInUser }) => {
  const [profile, setProfile] = useState({
    user_id: "",
    username: "",
    email: "",
    phone: "",
    store_name: "",
    address: "",
    origin: "",
    profileImage: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [files, setFiles] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`/get-profile/${loggedInUser}`);
        const data = response.data;

        // Assuming the address is formatted like "Dhaka Bangladesh, 1203"
        const addressParts = data.address.split(", ");
        const zipCode = addressParts.length > 1 ? addressParts.pop() : "";

        // Check if profile_picture is null, if so, set it to an empty string
        const profilePicture = data.profile_picture || "";

        setProfile({
          ...data,
          address: addressParts.join(", "), // Exclude the zip code from the address
          zipCode: zipCode || "",
          profile_picture: profilePicture, // Set profile_picture
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [loggedInUser]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "profile");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        // Set the profileImage state to the path of the selected image
        setProfile((prevProfile) => ({
          ...prevProfile,
          profileImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      if (changePassword && newPassword !== confirmNewPassword) {
        return toast.warning(
          "New password and confirm password did not match."
        );
      }
      setLoading(true);
      const profilePicturePath = imageFile
        ? imagePreview
        : profile.profile_picture;

      const addressWithZip = profile.zipCode
        ? `${profile.address}, ${profile.zipCode}`
        : profile.address;

      // Append user_id to profile data
      const profileData = {
        ...profile,
        address: addressWithZip,
        changePassword,
        newPassword,
        confirmNewPassword,
        profile_picture: files[0], // Set profile_picture to the image path
      };

      // Your form validation and submission logic
      const response = await axios.put("/update-profile", profileData); // Assuming this is your API endpoint
      toast.success("Profile updated successfully");
      console.log("Profile updated successfully:", response.data);
      setLoading(false);
      // Any further actions you need to take after successful submission
    } catch (error) {
      console.error("Error updating profile:", error);
      setLoading(false);
      // Handle errors appropriately, e.g., display error messages
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Edit Profile</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit} className="position-relative">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="username">User Id</label>
                        <input
                          type="text"
                          className="form-control"
                          id="user_id"
                          name="user_id"
                          value={profile.user_id}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="form-group col-md-5 mb-5">
                        <div class="pic-container pic-medium pic-circle mb-5">
                          <ImageLoader
                            src={profile.profile_picture}
                            className="rounded float-end"
                          ></ImageLoader>
                          <div class="pic-overlay">
                            <a>
                              <i
                                class="fa fa-window-close-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a>
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                        {/* <div className=" top-right">
                          <ImageLoader src={profile.profile_picture} className="rounded float-end"></ImageLoader>
                        </div> */}
                        <div className="image-upload image-upload-top-right ">
                          <FileUpload
                            // label={"Upload profile picture"}
                            updateFilesCb={setFiles}
                            maxFileSizeInBytes={5000000}
                            preSelectedFiles={files}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-7">
                        <label htmlFor="username">User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          name="username"
                          value={profile.username}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={profile.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          value={profile.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="store_name">Store Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="store_name"
                          name="store_name"
                          value={profile.store_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          value={profile.address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="zipCode">Zip Code</label>
                        <input
                          className="form-control"
                          id="zipCode"
                          type="text"
                          name="zipCode"
                          value={profile.zipCode}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="origin">Origin</label>
                        <input
                          type="text"
                          className="form-control"
                          id="origin"
                          name="origin"
                          value={profile.origin}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/* Change Password section */}
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="changePassword"
                            name="changePassword"
                            checked={changePassword}
                            onChange={(e) =>
                              setChangePassword(e.target.checked)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="changePassword"
                          >
                            Change Password
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Password Fields */}
                    {changePassword && (
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="newPassword">New Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="confirmNewPassword">
                            Confirm New Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={(e) =>
                              setConfirmNewPassword(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                    )}

                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
