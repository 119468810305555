import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const UpdateModal = ({ vendor, show, onClose }) => {
  const [updatedVendor, setUpdatedVendor] = useState({
    vendor_id: "",
    vendor_name: "",
    vendor_email: "",
    phone: "",
    store_name: "",
    origin: "",
    address: "",
    zipCode: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (vendor) {
      setUpdatedVendor({
        vendor_id: vendor.vendor_id,
        vendor_email: vendor.vendor_email,
        vendor_name: vendor.vendor_name,
        phone: vendor.phone,
        store_name: vendor.store_name,
        origin: vendor.origin,
        address: vendor.address,
        zipCode: vendor.zipCode,
      });
    }
  }, [vendor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedVendor({
      ...updatedVendor,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const addressWithZip = updatedVendor.zipCode
        ? `${updatedVendor.address}, ${updatedVendor.zipCode}`
        : updatedVendor.address;

      const response = await axios.put("/update-vendor", {
        vendor_id: updatedVendor.vendor_id,
        vendor_name: updatedVendor.vendor_name,
        vendor_email: updatedVendor.vendor_email,
        phone: updatedVendor.phone,
        store_name: updatedVendor.store_name,
        origin: updatedVendor.origin,
        address: addressWithZip,
      });

      console.log("Vendor updated successfully:", response.data);
      toast.success("Vendor updated successfully");
      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Error updating vendor:", error);
      toast.error("Error updating vendor");
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Vendor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="vendor_id">Vendor ID</label>
            <input
              type="text"
              className="form-control"
              id="vendor_id"
              name="vendor_id"
              value={updatedVendor.vendor_id}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="vendor_name">Name</label>
            <input
              type="text"
              className="form-control"
              id="vendor_name"
              name="vendor_name"
              value={updatedVendor.vendor_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="vendor_email">Email</label>
            <input
              type="email"
              className="form-control"
              id="vendor_email"
              name="vendor_email"
              value={updatedVendor.vendor_email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="store_name">Store Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="store_name"
                  name="store_name"
                  value={updatedVendor.store_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  className="form-control"
                  id="phone"
                  type="text"
                  name="phone"
                  value={updatedVendor.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  className="form-control"
                  id="address"
                  type="text"
                  name="address"
                  value={updatedVendor.address}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="zipCode">Zip Code</label>
                <input
                  className="form-control"
                  id="zipCode"
                  type="text"
                  name="zipCode"
                  value={updatedVendor.zipCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="origin">Country</label>
                <input
                  className="form-control"
                  id="origin"
                  type="text"
                  name="origin"
                  value={updatedVendor.origin}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update Vendor"}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
