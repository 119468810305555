import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import { AuthContext } from "../../../context/auth";
import Error403 from "../../../components/Error/403";
import { Link } from "react-router-dom"; // Import Link

export const BankAccountIndex = ({ loggedInUser }) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    bankName: "",
    branchName: "",
    accountType: "Saving", // Default value
    accountNumber: "",
    accountHolderName: "",
    debit: 0,
    credit: 0,
    totalBalance: 0,
    status: "Active", // Default status value
    note: "",
  });

  const [loading, setLoading] = useState(false);
  if (user) {
    const access = user.user?.role.find((role) => role.name === "accountOther");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  // Update form data as the user types in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.accountNumber) {
      toast.error("Account Number is required!");
      return;
    }

    setLoading(true);

    try {
      // Call API to add a new bank account
      const response = await axios.post("/add-bank", formData);
      toast.success("Bank Account added successfully");
      // Reset the form after successful submission
      setFormData({
        bankName: "",
        branchName: "",
        accountType: "Saving",
        accountNumber: "",
        accountHolderName: "",
        debit: 0,
        credit: 0,
        totalBalance: 0,
        status: "Active",
        note: "",
      });
    } catch (error) {
      console.error("Error adding bank account:", error);
      toast.error("Failed to add bank account. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row ">
        <div className="">
          <Link to="/account/bank" className="btn btn-primary float-right">
            See All Bank
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="card shadow p-4">
            <div className="card-header">
              <h3 className="card-title">Add Bank Account</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                {/* Bank Name */}
                <div className="form-group mb-3">
                  <label htmlFor="bankName">Bank Name</label>
                  <input
                    type="text"
                    id="bankName"
                    name="bankName"
                    className="form-control"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    placeholder="Enter Bank Name"
                    required
                  />
                </div>

                {/* Branch Name */}
                <div className="form-group mb-3">
                  <label htmlFor="branchName">Branch Name</label>
                  <input
                    type="text"
                    id="branchName"
                    name="branchName"
                    className="form-control"
                    value={formData.branchName}
                    onChange={handleInputChange}
                    placeholder="Enter Branch Name"
                    required
                  />
                </div>

                {/* Account Number */}
                <div className="form-group mb-3">
                  <label htmlFor="accountNumber">Account Number</label>
                  <input
                    type="text"
                    id="accountNumber"
                    name="accountNumber"
                    className="form-control"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                    placeholder="Enter Account Number"
                    required
                  />
                </div>

                {/* Account Type */}
                <div className="form-group mb-3">
                  <label htmlFor="accountType">Account Type</label>
                  <select
                    id="accountType"
                    name="accountType"
                    className="form-control"
                    value={formData.accountType}
                    onChange={handleInputChange}
                  >
                    <option value="Saving">Saving</option>
                    <option value="Current">Current</option>
                    <option value="Fixed">Fixed Deposit</option>
                    <option value="Joint">Joint Account</option>
                  </select>
                </div>

                {/* Account Holder Name */}
                <div className="form-group mb-3">
                  <label htmlFor="accountHolderName">Account Holder Name</label>
                  <input
                    type="text"
                    id="accountHolderName"
                    name="accountHolderName"
                    className="form-control"
                    value={formData.accountHolderName}
                    onChange={handleInputChange}
                    placeholder="Enter Account Holder's Name"
                    required
                  />
                </div>

                {/* Debit */}
                <div className="form-group mb-3">
                  <label htmlFor="debit">Debit Amount</label>
                  <input
                    type="number"
                    id="debit"
                    name="debit"
                    className="form-control"
                    value={formData.debit}
                    onChange={handleInputChange}
                    placeholder="Enter Debit Amount"
                    required
                  />
                </div>

                {/* Credit */}
                <div className="form-group mb-3">
                  <label htmlFor="credit">Credit Amount</label>
                  <input
                    type="number"
                    id="credit"
                    name="credit"
                    className="form-control"
                    value={formData.credit}
                    onChange={handleInputChange}
                    placeholder="Enter Credit Amount"
                    required
                  />
                </div>

                {/* Total Balance */}
                <div className="form-group mb-3">
                  <label htmlFor="totalBalance">Total Balance</label>
                  <input
                    type="number"
                    id="totalBalance"
                    name="totalBalance"
                    className="form-control"
                    value={formData.totalBalance}
                    onChange={handleInputChange}
                    placeholder="Enter Total Balance"
                    required
                  />
                </div>

                {/* Status */}
                <div className="form-group mb-3">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    className="form-control"
                    value={formData.status}
                    onChange={handleInputChange}
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Pending">Pending</option>
                  </select>
                </div>

                {/* Note */}
                <div className="form-group mb-3">
                  <label htmlFor="note">Note</label>
                  <textarea
                    id="note"
                    name="note"
                    className="form-control"
                    value={formData.note}
                    onChange={handleInputChange}
                    placeholder="Enter any additional notes"
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Adding..." : "Add Account"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
