import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { AuthContext } from "../context/auth";

const Layout = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const currentPage = location.pathname.substring(1);

  return (
    <>
      {user && <Navbar currentPage={currentPage} />}
      <Sidebar />
      <div
        className="content-wrapper"
        style={{ minHeight: "calc(100vh - 56px)" }}
      >
        <section className="content-header">
          <div className="container-fluid">
            <Outlet />
          </div>
        </section>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
