import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import $ from "jquery";
import Paginate from "./paginate";
import Papa from "papaparse";

const DataTable = forwardRef(
  (
    {
      data,
      children,
      setCurrentPage,
      totalItems,
      currentPage,
      customPaginate,
      exportToCSV,
      itemsPerPage,
    },
    ref
  ) => {
    const tableRef = useRef(null);
    const table = useRef(null);

    const reloadData = () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };

    useImperativeHandle(ref, () => ({
      update: () => {
        reloadData();
        table.current = $(tableRef.current).DataTable({
          searching: true,
          responsive: false,
          paging: !customPaginate,
        });
      },
    }));

    useEffect(() => {
      if (data) {
        table.current = $(tableRef.current).DataTable({
          searching: true,
          responsive: false,
          paging: !customPaginate,
        });
      }
      return () => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          $(tableRef.current).DataTable().destroy();
        }
      };
    }, [data]);

    const exportDataTableToCSV = () => {
      const dataTable = $(tableRef.current).DataTable();
      const columns = dataTable
        .columns()
        .header()
        .toArray()
        .map((column) => $(column).text());
      const data = dataTable.rows().data().toArray();
      const csvData = [columns, ...data];
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "datatable_export.csv";
      document.body.appendChild(link);
      link.click();
      if (document.body.contains(link)) {
        document.body.removeChild(link);
      }
      URL.revokeObjectURL(url);
    };

    return (
      <div>
        <div className="table-responsive">
          <table
            ref={tableRef}
            className="table table-striped table-bordered nowrap w-100"
          >
            {children}
          </table>
          {exportToCSV && (
            <button
              onClick={exportDataTableToCSV}
              className="btn btn-sm btn-primary mb-2 rounded-pill"
            >
              Export to CSV
            </button>
          )}
        </div>
        {customPaginate && (
          <Paginate
            totalItems={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>
    );
  }
);

export default DataTable;
