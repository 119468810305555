import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming you are using react-bootstrap for modal
import { toast } from "react-toastify";
import axios from "../../../api/axios";

const UpdateModal = ({ account, show, onClose }) => {
  const [formData, setFormData] = useState({
    recordName: "",
    recordType: "",
    amount: "",
    status: "",
    paymentMethod: "",
    paymentDate: "",
    note: "",
  });

  useEffect(() => {
    if (account) {
      setFormData({
        recordName: account.recordName || "",
        recordType: account.recordType || "",
        amount: account.amount || "",
        status: account.status || "",
        paymentMethod: account.paymentMethod || "",
        paymentDate: account.paymentDate || "",
        note: account.note || "",
      });
    }
  }, [account]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/update-other-account`, {
        recordId: account.recordId,
        ...formData,
      });
      toast.success("Account updated successfully");
      onClose(); // Close the modal after update
    } catch (error) {
      console.error("Error updating account:", error);
      toast.error("Failed to update account");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label>Record Name</label>
            <input
              type="text"
              name="recordName"
              className="form-control"
              value={formData.recordName}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Record Type</label>
            <input
              type="text"
              name="recordType"
              className="form-control"
              value={formData.recordType}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Amount</label>
            <input
              type="number"
              name="amount"
              className="form-control"
              value={formData.amount}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Status</label>
            <select
              name="status"
              className="form-control"
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>

          <div className="form-group">
            <label>Payment Method</label>
            <input
              type="text"
              name="paymentMethod"
              className="form-control"
              value={formData.paymentMethod}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Payment Date</label>
            <input
              type="date"
              name="paymentDate"
              className="form-control"
              value={formData.paymentDate}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Note</label>
            <textarea
              name="note"
              className="form-control"
              value={formData.note}
              onChange={handleInputChange}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateModal;
