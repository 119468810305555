// UpdateModal.js
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const UpdateModal = ({ sell, show, onClose }) => {
  const [updatedSellList, setUpdatedSellList] = useState({
    sell_id: "",
    customer_name: "",
    product_id: "",
    customer_address: "",
    customer_phone_number: "",
    seller_id: "",
    quantity: "",
    price: "",
  });

  // Update state when favList changes
  useEffect(() => {
    if (sell) {
      setUpdatedSellList({
        sell_id: sell.sell_id,
        customer_name: sell.customer_name,
        product_id: sell.product_id,
        customer_address: sell.customer_address,
        customer_phone_number: sell.customer_phone_number,
        seller_id: sell.seller_id,
        quantity: sell.quantity,
        price: sell.price,
      });
    }
  }, [sell]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedSellList({
      ...updatedSellList,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send PUT request to update favList
      const response = await axios.put("/update-sell", updatedSellList);
      console.log("Sell updated successfully:", response.data);
      onClose();
      toast.success("Sell updated successfully");
    } catch (error) {
      console.error("Error updating sell List:", error);
      toast.error("Failed to update sell List");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Sell</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="sell_id">Sell ID</label>
            <input
              type="text"
              className="form-control"
              id="sell_id"
              name="sell_id"
              value={updatedSellList.sell_id}
              disabled
            />
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="customer_name">Customer name</label>
                <input
                  type="text"
                  className="form-control"
                  id="customer_name"
                  name="customer_name"
                  value={updatedSellList.customer_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="customer_address">Customer address</label>
                <input
                  type="text"
                  className="form-control"
                  id="customer_address"
                  name="customer_address"
                  value={updatedSellList.customer_address}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="customer_phone_number">
                  Customer phone number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="customer_phone_number"
                  name="customer_phone_number"
                  value={updatedSellList.customer_phone_number}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="product_id">Product id</label>
                <input
                  type="text"
                  className="form-control"
                  id="product_id"
                  name="product_id"
                  value={updatedSellList.product_id}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="quantity">Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  value={updatedSellList.quantity}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="price">Price/piece</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Ex- 24 "
                  id="price"
                  name="price"
                  value={updatedSellList.price}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <Button variant="primary" type="submit">
            Update Sell
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
