export async function printDocument(targetRef) {
  var printContents = document.getElementById(targetRef)?.innerHTML;
  const popup = window.open("", "_blank", "width=600,height=600");
  popup.document.write(`<html><head><title>Invoice</title>`);
  popup.document.write("</head><body>");
  popup.document.write(printContents);
  popup.document.write(
    `<script>document.addEventListener("DOMContentLoaded", () => {setTimeout(() => {window.print()}, 500)})</script>`
  );
  popup.document.write("</body></html>");
  popup.document.close();
}

const units = [
  "",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
];
const teens = [
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];
const tens = [
  "",
  "Ten",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];
const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];

export function convertToWords(num) {
  if (num === 0) return "Zero";

  let words = "";

  // Handle the integer part
  let intPart = Math.floor(num);
  let decimalPart = Math.round((num - intPart) * 100);

  words = convertIntegerToWords(intPart);

  // Handle the decimal part
  if (decimalPart > 0) {
    words += ` and ${convertIntegerToWords(decimalPart)} Cents`;
  }

  return words.trim();
}

function convertIntegerToWords(num) {
  if (num === 0) return "";
  if (num < 10) return units[num];
  if (num < 20) return teens[num - 10];
  if (num < 100) return tens[Math.floor(num / 10)] + " " + units[num % 10];
  if (num < 1000)
    return (
      units[Math.floor(num / 100)] +
      " Hundred " +
      convertIntegerToWords(num % 100)
    );

  for (let i = 0; i < thousands.length; i++) {
    let unitValue = 1000 ** (i + 1);
    if (num < unitValue) {
      return (
        convertIntegerToWords(Math.floor(num / (unitValue / 1000))) +
        " " +
        thousands[i] +
        " " +
        convertIntegerToWords(num % (unitValue / 1000))
      );
    }
  }
  return "";
}

export function dateFormat(date) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
