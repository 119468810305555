import { useState } from "react";
import FileUpload from "../components/fileUpload/FileUpload";

export default function FileUploadPage() {
  const [files, setFiles] = useState([]);
  return (
    <div className="container">
      <FileUpload
        label={"Upload File"}
        updateFilesCb={setFiles}
        maxFileSizeInBytes={5000000}
        preSelectedFiles={files}
      />
    </div>
  );
}
