// UpdateModal.js
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const UpdateModal = ({ favList, show, onClose }) => {
  const [updatedFavList, setUpdatedFavList] = useState({
    favTable_id: "",
    fav_length: "",
    fav_width: "",
    fav_height: "",
    fav_weight: "",
    fav_priority: "",
  });

  // Update state when favList changes
  useEffect(() => {
    if (favList) {
      setUpdatedFavList({
        favTable_id: favList.favTable_id,
        fav_length: favList.fav_length,
        fav_width: favList.fav_width,
        fav_height: favList.fav_height,
        fav_weight: favList.fav_weight,
        fav_priority: favList.fav_priority,
      });
    }
  }, [favList]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedFavList({
      ...updatedFavList,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send PUT request to update favList
      const response = await axios.put("/update-favlist", updatedFavList);
      console.log("Favourite List updated successfully:", response.data);
      onClose();
      toast.success("Favourite List updated successfully");
    } catch (error) {
      console.error("Error updating Favourite List:", error);
      toast.error("Failed to update Favourite List");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Favourite List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="unique_category_id">Favourite List ID</label>
            <input
              type="text"
              className="form-control"
              id="favTable_id"
              name="favTable_id"
              value={updatedFavList.favTable_id}
              disabled
            />
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="category_name">Length</label>
                <input
                  type="number"
                  className="form-control"
                  id="fav_length"
                  name="fav_length"
                  value={updatedFavList.fav_length}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="category_name">Width</label>
                <input
                  type="number"
                  className="form-control"
                  id="fav_width"
                  name="fav_width"
                  value={updatedFavList.fav_width}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="category_name">Height</label>
                <input
                  type="number"
                  className="form-control"
                  id="fav_height"
                  name="fav_height"
                  value={updatedFavList.fav_height}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="category_name">Weight</label>
                <input
                  type="number"
                  className="form-control"
                  id="fav_weight"
                  name="fav_weight"
                  value={updatedFavList.fav_weight}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="category_name">Priority</label>
                <input
                  type="number"
                  className="form-control"
                  id="fav_priority"
                  name="fav_priority"
                  value={updatedFavList.fav_priority}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <Button variant="primary" type="submit">
            Update List
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
