import React, { useContext } from "react";
import { SettingsContext } from "../../context/settings";

export const Appearance = () => {
  const settings = useContext(SettingsContext);

  return (
    <style global jsx>
      {`
        :root {
          --application_color: ${settings.application_color || "#243b55"};
          --application_color_contrast: ${settings.application_color_contrast ||
          "#ffffff"};
          --application_color_gradient: ${settings.application_color_gradient ||
          "linear-gradient(90deg, rgb(17, 38, 55) 0%, rgb(13, 29, 48) 49%, rgb(10, 23, 38) 100%)"};
        }
      `}
    </style>
  );
};
