import Modal from "react-bootstrap/Modal";
import { Form, Button } from "react-bootstrap";
// import "../style.css";
import { useState } from "react";

const AssignUserModal = ({
  show,
  handleClose,
  pageSwitches,
  handleSwitchChange,
  formatPageName,
  routesConfig,
}) => {
  const [routes, setRouts] = useState([
    { name: "category", allow: false },
    { name: "inventory", allow: false },
    { name: "allUser", allow: false },
    { name: "vendor", allow: false },
    { name: "notice", allow: false },
    { name: "noticeRead", allow: false },
    { name: "assignRole", allow: false },
    { name: "sell", allow: false },
    { name: "transfer", allow: false },
    { name: "modifyTransfer", allow: false },
    { name: "profile", allow: false },
    { name: "waste", allow: false },
    { name: "wasteRead", allow: false },
    { name: "requisition", allow: false },
    { name: "modifyRequisition", allow: false },
    { name: "transaction", allow: false },
    { name: "accountCategory", allow: false },
    { name: "accountInventory", allow: false },
    { name: "accountOther", allow: false },
    { name: "settings", allow: false },
  ]);

  return (
    <Modal show={show} onHide={handleClose} className="custom-modal">
      <div className="card shadow bg-body rounded">
        <div className="card-header">
          <Modal.Header closeButton>
            <Modal.Title>Assign Pages</Modal.Title>
          </Modal.Header>
        </div>
      </div>

      <Modal.Body
        style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
      >
        <div className="card shadow p-3 mb-0 bg-body rounded">
          <div className="card-body" style={{ paddingRight: 0 }}>
            <Form.Group>
              <ul className="list-group list-group-flush">
                {routes.map((path, i) => (
                  <li className="list-group-item" key={path?.name || i}>
                    <Form.Group>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{path?.name?.toUpperCase()}</span>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Check
                              type="switch"
                              id={`${path?.name || i}-switch1`}
                              label=""
                              defaultChecked={path.allow}
                              key={path.allow}
                              onChange={(e) =>
                                setRouts((x) => {
                                  const data = [...x];
                                  data[i].allow = e.target.checked;
                                  pageSwitches(data);
                                  return data;
                                })
                              }
                            />
                          </div>
                          {/* <div className="col-md-6">
                            <Form.Check
                              type="switch"
                              id={`${path}-switch2`}
                              label="Edit"
                              checked={pageSwitches[path]?.switch2 || false}
                              onChange={handleSwitchChange(path, "switch2")}
                            />
                          </div> */}
                        </div>
                      </div>
                    </Form.Group>
                  </li>
                ))}
              </ul>
            </Form.Group>
          </div>
          <Button className="col-md-3" variant="primary" onClick={handleClose}>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssignUserModal;
