import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";
import { dateFormat } from "../../utils/functions";
import DeleteConfirmationModal from "./DeleteConfirmationMoal";
import UpdateModal from "./UpdateModal"; // Import the UpdateModal component
import { toast } from "react-toastify";

export default function TransactionGet() {
  const [transactions, settransactionList] = useState([]);
  const [transactionIdToDelete, setTransactionToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [transactionToUpdate, setTransactionToUpdate] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { user } = useContext(AuthContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 15;

  const fetchTransactionlists = async () => {
    try {
      const response = await axios.get(
        `/get-transaction?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      settransactionList(response?.data?.transactionList || []);
      setTotalItems(response?.data?.pagination.totalItems || []);
    } catch (error) {
      console.error("Error fetching transaction Lists:", error);
    }
  };

  useEffect(() => {
    fetchTransactionlists();
  }, [currentPage]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "transaction");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (transactionId) => {
    setTransactionToDelete(transactionId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("/delete-transaction", {
        data: { transactionId: transactionIdToDelete },
      });
      console.log("Transaction request deleted successfully:", response.data);
      fetchTransactionlists();
      toast.success("Transaction request deleted successfully");
    } catch (error) {
      console.error("Error deleting transaction:", error);
      toast.error("Failed to delete transaction");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleEdit = (transaction) => {
    setTransactionToUpdate(transaction);
    setShowUpdateModal(true);
  };

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded table-responsive">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link to="/" className="btn btn-primary float-right mr-2">
                      Add Transaction
                    </Link>
                  </div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">All Transaction List</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {transactions.length > 0 && (
                    <DataTable
                      data={transactions}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Transaction Id</th>
                          <th className="text-center">Transaction Type</th>
                          <th className="text-center">Amount</th>
                          <th className="text-center">Bank Account</th>
                          <th className="text-center">Category</th>
                          <th className="text-center">Sub Category</th>
                          <th className="text-center">Description</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Payment Type</th>
                          <th className="text-center">Custom RefNumber</th>
                          <th className="text-center">Revenue Type</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction) => (
                          <tr key={transaction.transactionId}>
                            <td className="text-secondary text-center">
                              {transaction.transactionId}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.transactionType}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.amount}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.bankAccount || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.category || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.subCategory || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.description}
                            </td>
                            <td className="text-secondary text-center">
                              {dateFormat(transaction.date)}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.status}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.paymentType}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.customRefNumber}
                            </td>
                            <td className="text-secondary text-center">
                              {transaction.revenueType}
                            </td>
                            <td className="text-secondary text-center">
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary btn-sm mr-2"
                                  onClick={() => handleEdit(transaction)}
                                  title="Edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>

                                <button
                                  className="btn btn-danger btn-sm ml-2"
                                  onClick={() =>
                                    handleDelete(transaction.transactionId)
                                  }
                                >
                                  <i className="fas fa-trash-alt mr-1"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DeleteConfirmationModal
        transactionIdToDelete={setTransactionToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />

      <UpdateModal
        transaction={transactionToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
      />
    </div>
  );
}
