import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const UpdateModal = ({ transaction, show, onClose }) => {
  const [updatedTransaction, setUpdatedTransaction] = useState({
    transactionId: "",
    transactionType: "",
    amount: 0,
    bankAccount: "",
    category: "",
    subCategory: "",
    description: "",
    date: "",
    status: "",
    paymentType: "",
    customRefNumber: "",
    revenueType: "",
  });

  // Populate the state with the current transaction values when available
  useEffect(() => {
    if (transaction) {
      setUpdatedTransaction({
        transactionId: transaction.transactionId,
        transactionType: transaction.transactionType,
        amount: transaction.amount,
        bankAccount: transaction.bankAccount,
        category: transaction.category,
        subCategory: transaction.subCategory,
        description: transaction.description,
        date: transaction.date,
        status: transaction.status,
        paymentType: transaction.paymentType,
        customRefNumber: transaction.customRefNumber,
        revenueType: transaction.revenueType,
      });
    }
  }, [transaction]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedTransaction({
      ...updatedTransaction,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send PUT request to update the transaction
      const response = await axios.put(
        "/update-transaction",
        updatedTransaction
      );
      console.log("Transaction updated successfully:", response.data);
      onClose(); // Close the modal after a successful update
      toast.success("Transaction updated successfully");
    } catch (error) {
      console.error("Error updating transaction:", error);
      toast.error("Failed to update transaction");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="transactionId">Transaction ID</label>
            <input
              type="text"
              className="form-control"
              id="transactionId"
              name="transactionId"
              value={updatedTransaction.transactionId}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="transactionType">Transaction Type</label>
            <select
              className="form-control"
              id="transactionType"
              name="transactionType"
              value={updatedTransaction.transactionType}
              onChange={handleInputChange}
              required
            >
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              className="form-control"
              id="amount"
              name="amount"
              value={updatedTransaction.amount}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="bankAccount">Bank Account</label>
            <input
              type="text"
              className="form-control"
              id="bankAccount"
              name="bankAccount"
              value={updatedTransaction.bankAccount}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <input
              type="text"
              className="form-control"
              id="category"
              name="category"
              value={updatedTransaction.category}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="subCategory">Sub Category</label>
            <input
              type="text"
              className="form-control"
              id="subCategory"
              name="subCategory"
              value={updatedTransaction.subCategory}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={updatedTransaction.description}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              className="form-control"
              id="date"
              name="date"
              value={updatedTransaction.date}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              className="form-control"
              id="status"
              name="status"
              value={updatedTransaction.status}
              onChange={handleInputChange}
              required
            >
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="paymentType">Payment Type</label>
            <select
              className="form-control"
              id="paymentType"
              name="paymentType"
              value={updatedTransaction.paymentType}
              onChange={handleInputChange}
              required
            >
              <option value="cash">Cash</option>
              <option value="bank">Bank</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="customRefNumber">Custom Ref Number</label>
            <input
              type="text"
              className="form-control"
              id="customRefNumber"
              name="customRefNumber"
              value={updatedTransaction.customRefNumber}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="revenueType">Revenue Type</label>
            <select
              className="form-control"
              id="revenueType"
              name="revenueType"
              value={updatedTransaction.revenueType}
              onChange={handleInputChange}
              required
            >
              <option value="randomSlab">Random Slab</option>
              <option value="jobWork">Job Work</option>
              <option value="trading">Trading</option>
              <option value="others">Others</option>
            </select>
          </div>

          <Button variant="primary" type="submit">
            Update Transaction
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
