import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import { AuthContext } from "../../../context/auth";
import Error403 from "../../../components/Error/403";
import DataTable from "../../../components/table";

export const BankAccountGet = () => {
  const [banks, setBanks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { user } = useContext(AuthContext);
  const itemsPerPage = 15;

  const fetchBanks = async () => {
    try {
      const response = await axios.get(
        `/get-banks?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setBanks(response.data.accountList);
      setTotalItems(response.data.pagination.totalItems);
      // Set the totals from the response
      setTotalDebit(response.data.totals.totalDebit);
      setTotalCredit(response.data.totals.totalCredit);
      setTotalBalance(response.data.totals.totalBalance);
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, [currentPage]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "accountOther");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleRowClick = (bankId) => {
    setExpandedRow(expandedRow === bankId ? null : bankId);
  };

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row mb-4">
                <div className="col-md-4">
                  <div className="card shadow-sm p-3 mb-4">
                    <h4 className="card-title">Total Credit</h4>
                    <p className="card-text"> ৳ {totalCredit.toFixed(2)}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card shadow-sm p-3 mb-4">
                    <h4 className="card-title">Total Debit</h4>
                    <p className="card-text"> ৳ {totalDebit.toFixed(2)}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card shadow-sm p-3 mb-4">
                    <h4 className="card-title">Total Balance</h4>
                    <p className="card-text"> ৳ {totalBalance.toFixed(2)}</p>
                  </div>
                </div>
              </div>

              <div className="card shadow p-3 mb-5 bg-body rounded table-responsive">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <h3 className="card-title">Bank Accounts List</h3>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-4 offset-md-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {banks.length > 0 ? (
                  <div className="card-body">
                    <DataTable data={banks}>
                      <thead>
                        <tr>
                          <th className="text-center">Bank ID</th>
                          <th className="text-center">Bank Name</th>
                          <th className="text-center">Branch Name</th>
                          <th className="text-center">Account Number</th>
                          <th className="text-center">Account Type</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {banks
                          .filter(
                            (bank) =>
                              bank.bankName
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              bank.branchName
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              bank.accountNumber
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                          )
                          .map((bank) => (
                            <React.Fragment key={bank.bankId}>
                              <tr
                                onClick={() => handleRowClick(bank.bankId)}
                                style={{ cursor: "pointer" }}
                              >
                                <td className="text-secondary text-center">
                                  {bank.bankId}
                                </td>
                                <td className="text-secondary text-center">
                                  {bank.bankName}
                                </td>
                                <td className="text-secondary text-center">
                                  {bank.branchName}
                                </td>
                                <td className="text-secondary text-center">
                                  {bank.accountNumber}
                                </td>
                                <td className="text-secondary text-center">
                                  {bank.accountType}
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => handleRowClick(bank.bankId)}
                                  >
                                    {expandedRow === bank.bankId
                                      ? "Collapse"
                                      : "Expand"}
                                  </button>
                                </td>
                              </tr>
                              {expandedRow === bank.bankId && (
                                <tr>
                                  <td colSpan="6" className="p-4">
                                    <div className="card shadow p-4">
                                      <h5>Bank Account Details</h5>
                                      <p>
                                        <strong>Account Holder:</strong>{" "}
                                        {bank.accountHolderName}
                                      </p>
                                      <p>
                                        <strong>Account Number:</strong>{" "}
                                        {bank.accountNumber}
                                      </p>
                                      <p>
                                        <strong>Debit Amount:</strong> ৳
                                        {bank.debit.toFixed(2)}
                                      </p>
                                      <p>
                                        <strong>Credit Amount:</strong> ৳
                                        {bank.credit.toFixed(2)}
                                      </p>
                                      <p>
                                        <strong>Total Balance:</strong> ৳
                                        {bank.totalBalance.toFixed(2)}
                                      </p>
                                      <p>
                                        <strong>Status:</strong> {bank.status}
                                      </p>
                                      <p>
                                        <strong>Note:</strong> {bank.note}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </DataTable>
                  </div>
                ) : (
                  <div className="card-body">
                    <p>No data available.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
