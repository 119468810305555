import { useEffect, useState } from "react";

function paginate(currentPage, totalItems, itemsPerPage) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const visiblePages = 10;
  let startPage, endPage;

  if (totalPages <= visiblePages) {
    startPage = 1;
    endPage = totalPages;
  } else {
    const halfVisiblePages = Math.floor(visiblePages / 2);
    if (currentPage <= halfVisiblePages) {
      startPage = 1;
      endPage = visiblePages;
    } else if (currentPage + halfVisiblePages >= totalPages) {
      startPage = totalPages - visiblePages + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - halfVisiblePages;
      endPage = currentPage + halfVisiblePages;
    }
  }

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  return {
    currentPage,
    totalPages,
    startPage,
    endPage,
    pages,
  };
}

export default function Paginate({
  currentPage,
  totalItems,
  itemsPerPage,
  setCurrentPage,
}) {
  const [calc, setCalc] = useState({
    currentPage,
    totalPages: 1,
    startPage: 1,
    endPage: 1,
    pages: [1],
  });

  useEffect(() => {
    const r = paginate(currentPage, totalItems, itemsPerPage);
    setCalc(r);
  }, [currentPage, totalItems, itemsPerPage]);

  return (
    <div className="pagination_x">
      <ul className="pagination">
        {calc.endPage > 10 && (
          <>
            <li className="page-item">
              <span className="page-link" onClick={() => setCurrentPage(1)}>
                1
              </span>
            </li>
            <li className="page-item">
              <span className="page-link" aria-disabled="true">
                ...
              </span>
            </li>
          </>
        )}
        {calc.pages.map((x, i) => (
          <li className={"page-item"} key={x + i}>
            <span
              onClick={() => setCurrentPage(x)}
              className={`page-link ${x === currentPage ? "active" : ""}`}
              aria-checked={x === currentPage}
            >
              {x}
            </span>
          </li>
        ))}
        {calc.endPage < calc.totalPages - 10 && (
          <>
            <li className="page-item">
              <span className="page-link" aria-disabled="true">
                ...
              </span>
            </li>
            <li className="page-item">
              <span
                className="page-link"
                onClick={() => setCurrentPage(calc.totalPages)}
              >
                {calc.totalPages}
              </span>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
