import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";

import { Link } from "react-router-dom"; // Import Link
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import axios from "../../api/axios";
import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";

// Declaring handleEdit and handleDelete outside of components

export const CategoryAdd = () => {
  const [formData, setFormData] = useState({
    unique_category_id: "",
    category_name: "",
  });
  const [lastFiveCategories, setLastFiveCategories] = useState([]);

  const fetchLastFiveCategories = async () => {
    try {
      const response = await axios.get("/get-category");
      setLastFiveCategories(response.data.slice(-5).reverse()); // Reverse to display latest entries first
    } catch (error) {
      console.error("Error fetching last five categories:", error);
    }
  };
  const { user } = useContext(AuthContext);

  useEffect(() => {
    // Fetch last 5 categories

    fetchLastFiveCategories();
  }, []);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "category");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/add-category", formData);
      toast.success("Category added successfully");
      fetchLastFiveCategories();
      setFormData({ unique_category_id: "", category_name: "" }); // Clear input fields after successful submission
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category");
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              {/* here add a button to redirect to add category page */}
              <Link to="/category" className="btn btn-primary float-right">
                See All Category
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Category</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="unique_category_id">
                        Unique Category ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- 19-39454"
                        id="unique_category_id"
                        name="unique_category_id"
                        value={formData.unique_category_id}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="category_name">Category Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Marble "
                        id="category_name"
                        name="category_name"
                        value={formData.category_name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Add Category
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Latest Five Categories</h3>
                </div>
                <div className="card-body">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th className="text-center">Category ID</th>
                        <th className="text-center">Category Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveCategories.map((category) => (
                        <tr key={category.unique_category_id}>
                          <td className="text-secondary text-center">
                            {category.unique_category_id}
                          </td>
                          <td className="text-secondary text-center">
                            {category.category_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const CategoryGet = () => {
  const [categories, setCategories] = useState([]);
  const [categoryToUpdate, setCategoryToUpdate] = useState(null);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/get-category");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchCategories();
  }, []);
  if (user) {
    const access = user.user?.role.find((role) => role.name === "category");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (unique_category_id) => {
    setCategoryIdToDelete(unique_category_id);
    setShowDeleteModal(true);
  };

  const handleEdit = (categories) => {
    const { unique_category_id, category_name } = categories;
    const category = {
      unique_category_id,
      category_name,
    };
    setCategoryToUpdate(category);
    setShowUpdateModal(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await axios.put("/update-category", {
        data: {
          unique_category_id: categoryToUpdate.unique_category_id,
          category_name: categoryToUpdate.category_name,
        },
      });

      if (response.status === 200) {
        toast.success("Category updated successfully");

        fetchCategories();

        setShowUpdateModal(false);
      }
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("Failed to update category");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete("delete-category", {
        data: { categoryId: categoryIdToDelete },
      });
      fetchCategories();
      toast.success("Category deleted successfully");
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Failed to delete category");
    } finally {
      setShowDeleteModal(false);
    }
  };

  // const filteredCategories = categories.filter((category) =>

  //   category.category_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredCategories = categories.filter((category) => {
    const { unique_category_id, category_name } = category;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = unique_category_id ? unique_category_id.toString() : "";
    const name = category_name ? category_name.toString().toLowerCase() : "";

    // Check if any of the fields contain the search term
    return id.includes(searchTerms) || name.includes(searchTerms);
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link
                      to="/category/add"
                      className="btn btn-primary float-right mr-2"
                    >
                      Add Category
                    </Link>
                  </div>
                </div>
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Categories</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-3 py-3">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {filteredCategories.length === 0 ? (
                    <div className="text-center">No categories found.</div>
                  ) : (
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">ID</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCategories.map((category) => (
                          <tr key={category.unique_category_id}>
                            <td className="text-secondary text-center">
                              {category.unique_category_id}
                            </td>
                            <td className="text-secondary text-center">
                              {category.category_name}
                            </td>
                            <td className="text-secondary text-center">
                              <button
                                className="btn btn-primary btn-sm mr-2"
                                title="Edit"
                                onClick={() => handleEdit(category)}
                              >
                                <i className="fas fa-edit mr-1"></i>
                              </button>

                              <button
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() =>
                                  handleDelete(category.unique_category_id)
                                }
                              >
                                <i className="fas fa-trash-alt mr-1"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateModal
        category={categoryToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />
      <DeleteConfirmationModal
        categoryIdToDelete={categoryIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};
