import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { Link } from "react-router-dom"; // Import Link
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FavouriteListAdd = () => {
  const [formData, setFormData] = useState({
    favTable_id: "",
    fav_length: "",
    fav_width: "",
    fav_height: "",
    fav_weight: "",
    fav_priority: "",
  });
  const [lastFiveFavList, setLastFiveFavList] = useState([]);

  const fetchLastFiveFAvListData = async () => {
    try {
      const response = await axios.get("/get-favlist");
      setLastFiveFavList(response.data.slice(-5).reverse()); // Reverse to display latest entries first
    } catch (error) {
      console.error("Error fetching last five List:", error);
    }
  };

  // Call the function when the component mounts
  useEffect(() => {
    fetchLastFiveFAvListData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/add-favlist", formData);
      console.log("List added successfully:", response.data);
      toast.success("List added successfully");
      fetchLastFiveFAvListData();
      setFormData({
        favTable_id: "",
        fav_length: "",
        fav_width: "",
        fav_height: "",
        fav_weight: "",
        fav_priority: "",
      }); // Clear input fields after successful submission
    } catch (error) {
      console.error("Error adding List:", error);
      toast.error("Failed to add List");
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              {/* here add a button to redirect to add category page */}
              <Link to="/fav-list" className="btn btn-primary float-right">
                See All Favourite List
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Favourite LIst</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="fav_length">Length</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 250.6 "
                            id="fav_length"
                            name="fav_length"
                            value={formData.fav_length}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="fav_width">Width</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 150.6 "
                            id="fav_width"
                            name="fav_width"
                            value={formData.fav_width}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col -md-4">
                        <div className="form-group">
                          <label htmlFor="fav_height">Height</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 50.00 "
                            id="fav_height"
                            name="fav_height"
                            value={formData.fav_height}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="fav_weight">Weight</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 2000 "
                            id="fav_weight"
                            name="fav_weight"
                            value={formData.fav_weight}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="fav_priority">Priority</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 1"
                            id="fav_priority"
                            name="fav_priority"
                            value={formData.fav_priority}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Add List
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Latest Five List</h3>
                </div>
                <div className="card-body">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th className="text-center">Favourite List ID</th>
                        <th className="text-center">Length</th>
                        <th className="text-center">Width</th>
                        <th className="text-center">Height</th>
                        <th className="text-center">Weight</th>
                        <th className="text-center">Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveFavList.map((favList) => (
                        <tr key={favList.favTable_id}>
                          <td className="text-secondary text-center">
                            {favList.favTable_id}
                          </td>
                          <td className="text-secondary text-center">
                            {favList.fav_length}
                          </td>
                          <td className="text-secondary text-center">
                            {favList.fav_width}
                          </td>
                          <td className="text-secondary text-center">
                            {favList.fav_height}
                          </td>
                          <td className="text-secondary text-center">
                            {favList.fav_weight}
                          </td>
                          <td className="text-secondary text-center">
                            {favList.fav_priority}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const FavouriteListGet = () => {
  const [favLists, setFavLists] = useState([]);
  const [favlistToUpdate, setFavlistToUpdate] = useState(null);
  const [favlistIdToDelete, setFavlistIdToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchFavlists = async () => {
    try {
      const response = await axios.get("/get-favlist");
      setFavLists(response.data);
    } catch (error) {
      console.error("Error fetching Favourite Lists:", error);
    }
  };

  useEffect(() => {
    fetchFavlists();
  }, []);

  const handleDelete = (favTable_id) => {
    setFavlistIdToDelete(favTable_id);
    setShowDeleteModal(true);
  };

  const handleEdit = (favList) => {
    setFavlistToUpdate(favList);
    setShowUpdateModal(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await axios.put("/update-favlist", {
        data: favlistToUpdate,
      });

      if (response.status === 200) {
        console.log("Favourite List updated successfully:", response.data);
        toast.success("Favourite List updated successfully");
        fetchFavlists();
        setShowUpdateModal(false);
      }
    } catch (error) {
      console.error("Error updating Favourite List:", error);
      toast.error("Failed to update Favourite List");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("delete-favlist", {
        data: { favTable_id: favlistIdToDelete },
      });
      console.log("Favourite List deleted successfully:", response.data);
      fetchFavlists();
      toast.success("Favourite List deleted successfully");
    } catch (error) {
      console.error("Error deleting Favourite List:", error);
      toast.error("Failed to delete Favourite List");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredFavLists = favLists.filter((favList) => {
    const {
      favTable_id,
      fav_length,
      fav_width,
      fav_height,
      fav_weight,
      fav_priority,
    } = favList;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = favTable_id ? favTable_id.toString() : "";
    const length = fav_length ? fav_length.toString().toLowerCase() : "";
    const width = fav_width ? fav_width.toString().toLowerCase() : "";
    const height = fav_height ? fav_height.toString().toLowerCase() : "";
    const weight = fav_weight ? fav_weight.toString().toLowerCase() : "";
    const priority = fav_priority ? fav_priority.toString().toLowerCase() : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      length.includes(searchTerms) ||
      width.includes(searchTerms) ||
      height.includes(searchTerms) ||
      weight.includes(searchTerms) ||
      priority.includes(searchTerms)
    );
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    <Link
                      to="/fav-list/add"
                      className="btn btn-primary float-right"
                    >
                      Add Favourite List
                    </Link>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Favourite List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {filteredFavLists.length === 0 ? (
                    <div className="text-center">
                      No item found in the list.
                    </div>
                  ) : (
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Favourite List ID</th>
                          <th className="text-center">Length</th>
                          <th className="text-center">Width</th>
                          <th className="text-center">Height</th>
                          <th className="text-center">Weight</th>
                          <th className="text-center">Priority</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredFavLists.map((favList) => (
                          <tr key={favList.favTable_id}>
                            <td className="text-secondary text-center">
                              {favList.favTable_id}
                            </td>
                            <td className="text-secondary text-center">
                              {favList.fav_length}
                            </td>
                            <td className="text-secondary text-center">
                              {favList.fav_width}
                            </td>
                            <td className="text-secondary text-center">
                              {favList.fav_height}
                            </td>
                            <td className="text-secondary text-center">
                              {favList.fav_weight}
                            </td>
                            <td className="text-secondary text-center">
                              {favList.fav_priority}
                            </td>
                            <td className="text-secondary text-center">
                              <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => handleEdit(favList)}
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  handleDelete(favList.favTable_id)
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateModal
        favList={favlistToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />
      <DeleteConfirmationModal
        favlistIdToDelete={favlistIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};
