import React, { useContext, useRef } from "react";
import Modal from "react-modal";
import { Transfer } from "../../layout/deliveryChalan";
import { printDocument } from "../../utils/functions";

export const TransferDetailsModal = ({
  isOpen,
  onRequestClose,
  transferDetails,
}) => {
  const pdfRef = useRef();

  if (!transferDetails) return null;

  const {
    transfer_id,
    store_manager_id,
    gatepass,
    transfer_location,
    sell_id, // Default to an empty array if products is undefined
    status,
  } = transferDetails;
  const printTransfer = async () => {
    await printDocument("inv_content");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Transfer Details"
    >
      <div ref={pdfRef} className="p-3">
        <h2>Delivery Chalan</h2>
        <div>
          <p>
            <strong>Transfer ID:</strong> {transfer_id}
          </p>
          <p>
            <strong>Store Manager Id:</strong> {store_manager_id}
          </p>
          <p>
            <strong>Gate Pass:</strong> {gatepass}
          </p>
          <p>
            <strong>Transfer Location:</strong> {transfer_location}
          </p>
          <p>
            <strong>Sell Id:</strong> {JSON.parse(sell_id)?.join(", ")}
          </p>
          <p>
            <strong>Status:</strong> {status}
          </p>
        </div>

        <div className="d-flex">
          <button
            onClick={onRequestClose}
            className="btn btn-danger btn-sm m-1"
          >
            Close
          </button>
          <button
            onClick={printTransfer}
            className="btn btn-primary btn-sm m-1"
          >
            Print
          </button>
        </div>

        <div
          id="inv_content"
          style={{
            minHeight: "max-content",
            display: "none",
          }}
        >
          <Transfer data={transferDetails} />
        </div>
      </div>
    </Modal>
  );
};
