import axios from "axios";
import CryptoJS from "crypto-js";

// export default axios.create({
//   baseURL: "http://200.69.21.240/api/",
// });
function getUserDataFromStorage() {
  try {
    const data = localStorage?.getItem("x_sid");
    if (data) {
      const encKey = "625ds6ds6g25dsagg256sdg6sgd2";
      const decrData = CryptoJS.AES.decrypt(data, encKey);
      const decrDataStr = decrData.toString(CryptoJS.enc.Utf8);

      return JSON.parse(decrDataStr);
    }
  } catch (err) {
    return null;
  }
}

const CreateAxiosApi = () => {
  let token = getUserDataFromStorage()?.user.token || null;
  return axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`, // Example: Authorization header
      "Content-Type": "application/json", // Example: Content-Type header
      // You can add more headers as needed
    },
  });
};

export default CreateAxiosApi();
