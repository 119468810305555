import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "../../api/axios";

const InventoryGraph = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      width: 650, // Adjust width here
      type: "pie",
    },
    labels: [], // Labels for the pie chart segments
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300, // Adjust width for responsiveness if needed
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const fetchInventoryData = async () => {
      try {
        const response = await axios.get("/get-all-inventory-count");

        if (response && response.data && Array.isArray(response.data.data)) {
          // Convert response data to the format required by the pie chart
          const inventoryData = response.data.data.map((item) => ({
            item: item._id, // Category ID
            quantity: item.itemCount, // Item count
          }));

          // Update state with inventory data and set labels
          setInventoryData(inventoryData);
          setOptions((prevOptions) => ({
            ...prevOptions,
            labels: inventoryData.map((item) => item.item), // Set labels to category IDs
          }));
        } else {
          console.error("Invalid response data:", response);
        }
      } catch (error) {
        console.error("Error fetching inventory data:", error);
      }
    };

    fetchInventoryData();
  }, []);

  const seriesData = inventoryData.map((item) => item.quantity);

  return (
    <div className="card mb-5 border-0 shadow">
      <div className="card-header py-3 text-center">
        <b>Inventory Items by Category</b>
      </div>
      <div className="card-body">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={seriesData}
            type="pie"
            width={500}
          />
        </div>
      </div>
    </div>
  );
};

export default InventoryGraph;
