import React, { useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";

import { Link } from "react-router-dom"; // Import Link
import axios from "../../api/axios";
import Select from "react-select";
import { SellDetailsModal } from "./SellDetailsModal"; // Import the SellDetailsModal component
import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";

const SellIndexAdd = ({ loggedInUser }) => {
  const [formData, setFormData] = useState({
    sell_id: "",
    customer_name: "",
    customer_address: "",
    customer_phone_number: "",
    seller_id: "",
    seller_name: "",
    discount_amount: "",
    carring_charge: "",
    product: [
      {
        product_id: "",
        product_name: "",
        quantity: "",
        price: "",
        customSize: false,
        custom_length: "",
        custom_width: "",
        area: null,
        unique_category_id: "",
        vendor_id: "",
      },
    ],
  });
  const [productData, setProductData] = useState([]);
  const [showProductInp, setShowProductInp] = useState(false);
  const [lastFiveSells, setLastFiveSells] = useState([]);
  const [sellToShow, setSellToShow] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lengthUnit, setLengthUnit] = useState("mm");
  const [carryingCharge, setCarringCharge] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountType, setDiscountType] = useState("plain");

  const [selectedProduct, setSelectedProduct] = useState({
    value: "",
    label: "",
  });
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "",
  });
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const inputForm = useRef(null);
  const { user } = useContext(AuthContext);

  const initProductInput = {
    product_id: "",
    product_name: "",
    quantity: "",
    price: "",
    customSize: false,
    custom_length: null,
    custom_width: null,
    area: null,
    unique_category_id: selectedCategory?.value,
    vendor_id: "",
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  //fetching sell
  const fetchLastFiveSells = async () => {
    try {
      const response = await axios.get("/get-sell?page=1&itemsPerPage=5");
      setLastFiveSells(response.data.sells);
    } catch (error) {
      console.error("Error fetching last five sells:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-all-inventory");
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setLoading(false);
    }
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    const updatedProducts = { ...formData.product };
    updatedProducts[name] = value;
    setFormData({
      ...formData,
      product: updatedProducts,
    });

    // Calculate area if custom size is enabled
    if (name === "custom_length" || name === "custom_width") {
      if (value > 0) {
        const length =
          name === "custom_length" ? value : updatedProducts.custom_length;
        const width =
          name === "custom_width" ? value : updatedProducts.custom_width;
        updatedProducts.area = length * width;
        setFormData({
          ...formData,
          product: updatedProducts,
        });
      } else {
        toast.error(
          `${
            name.charAt(0).toUpperCase() + name.slice(7) // Adjusted slice to remove "custom"
          } must be greater than 0`
        );
      }
    }
  };

  const handleProductSelectChange = (selectedOption) => {
    const data = { ...formData };
    data.product.product_id = selectedOption.value;
    setFormData(data);
    setSelectedProduct(selectedOption);
  };

  useEffect(() => {
    fetchLastFiveSells();
    fetchProducts();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setFilteredProducts(
        products.filter(
          (product) => product.category_name === selectedCategory.value
        )
      );
    } else {
      setFilteredProducts(products);
    }
  }, [selectedCategory, products]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "sell");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const inchesToMillimeters = (inches) => {
    return inches * 25.4; // 1 inch = 25.4 millimeters
  };

  const getTotalPrice = productData.reduce(
    (acc, product) => acc + product.price * product.quantity,
    0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = { ...formData };
      delete data.product;
      data.products = productData;
      data.carring_charge = carryingCharge || 0;
      data.discount_amount = discountAmount || 0;

      const response = await axios
        .post("/add-sell", {
          ...data,
          seller_id: loggedInUser,
          seller_name: user?.user.name,
        })
        .then((r) => r.data);
      if (response.success) {
        toast.success("Sell added successfully");
        fetchLastFiveSells();
        setFormData({
          sell_id: "",
          customer_name: "",
          customer_address: "",
          customer_phone_number: "",
          seller_id: "",
          product: initProductInput,
        });
        setSelectedProduct({ value: "", label: "" });
        setSelectedCategory({ value: "", label: "" });
        setProductData([]);
        setDiscountAmount(0);
        setDiscountType("plain");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error adding sell:", error);
      toast.error(error.message || "Failed to add sell");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCustomSizeChange = (e) => {
    const updatedProducts = { ...formData.product };
    updatedProducts.customSize = e.target.checked;
    if (!e.target.checked) {
      updatedProducts.length = "";
      updatedProducts.width = "";
      updatedProducts.area = null;
    }
    setFormData({
      ...formData,
      product: updatedProducts,
    });
  };

  const addProduct = () => {
    console.log(formData.product);
    if (!inputForm.current.reportValidity()) {
      return toast.error("Please fill up all required fields");
    }
    setShowProductInp(true);

    // Extracting product_id from selectedProduct
    const product_id = selectedProduct ? selectedProduct.value.id : "";
    const product_name = selectedProduct ? selectedProduct.value.name : "";
    const vendorId = selectedProduct ? selectedProduct.value.vendorId : "";
    const categoryId = selectedCategory ? selectedCategory.value : "";

    setSelectedProduct({ value: "", label: "" });
    setSelectedCategory({ value: "", label: "" });

    if (formData.product?.product_id?.id?.length > 0) {
      const productToAdd = {
        ...formData.product,
        product_id: product_id,
        product_name: product_name,
        vendor_id: vendorId,
        unique_category_id: categoryId,
      };

      if (productToAdd.customSize) {
        if (!productToAdd.custom_width) {
          return toast.warning("Please enter custom width input value");
        } else if (!productToAdd.custom_length) {
          return toast.warning("Please enter custom length input value");
        }
      }
      if (lengthUnit === "Inch" && productToAdd.customSize) {
        // Convert custom size if lengthUnit is inches
        productToAdd.custom_length = inchesToMillimeters(
          productToAdd.custom_length
        );
        productToAdd.custom_width = inchesToMillimeters(
          productToAdd.custom_width
        );
      }

      if (!productToAdd.customSize) {
        const productData = products.find(
          (x) => x.inventory_id === productToAdd.product_id
        );
        if (productData) {
          productToAdd.custom_length = productData.init_slab_length;
          productToAdd.custom_width = productData.init_slab_weidth;
        }
      }

      setProductData((p) => [...p, productToAdd]);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      product: initProductInput,
    }));
  };

  const removeProduct = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      product: initProductInput,
    }));
    setSelectedProduct({});
    setSelectedCategory({});
  };

  const handleDetails = (sell) => {
    setSellToShow(sell);
    setShowDetailsModal(true);
  };

  const handleProductDelete = (index) => {
    const data = [...productData];
    data.splice(index, 1);
    setProductData(data);
  };

  const handleDiscountAmountChange = (e) => {
    if (discountType === "plain") {
      setDiscountAmount(+e.target.value);
    } else {
      const totalAmount = getTotalPrice;
      const percent = e.target.value > 100 ? 100 : +e.target.value;
      const result = (percent / 100) * totalAmount;
      setDiscountAmount(result);
    }
  };

  const calculateWaste = (selectedProduct, customLength, customWidth) => {
    const productLength = selectedProduct?.value?.length || 0;
    const productWidth = selectedProduct?.value?.width || 0;

    const wasteLength = Math.max(productLength - customLength, 0);
    const wasteWidth = Math.max(productWidth - customWidth, 0);
    return {
      wasteLength,
      wasteWidth,
    };
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="">
              <Link to="/sell" className="btn btn-primary float-right">
                See All Sell
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Sell</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit} ref={inputForm}>
                    <div className="form-group">
                      <label htmlFor="customer_name">Customer Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Alex"
                        id="customer_name"
                        name="customer_name"
                        value={formData.customer_name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="customer_address">Customer Address</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- New York "
                        id="customer_address"
                        name="customer_address"
                        value={formData.customer_address}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="customer_phone_number">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- 01********* "
                        id="customer_phone_number"
                        name="customer_phone_number"
                        value={formData.customer_phone_number}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    {showProductInp && (
                      <div className="product-group mb-3">
                        <div className="form-group">
                          <label htmlFor="category_name">Category Name</label>
                          <Select
                            options={categories.map((category) => ({
                              value: category.unique_category_id,
                              label: category.category_name,
                            }))}
                            value={selectedCategory}
                            onChange={setSelectedCategory}
                            name="unique_category_id" // Name set to unique_category_id
                            placeholder="Select Category"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor={`product_id_`}>Product Id</label>
                          <Select
                            options={filteredProducts
                              .filter((product) => product.quantity > 0)
                              .map((product) => ({
                                value: {
                                  id: product.inventory_id,
                                  length: product.init_slab_length,
                                  width: product.init_slab_weidth,
                                  vendorId: product.vendor_id,
                                  name: product.product_name,
                                },
                                label: `${product.product_name} (${
                                  product.inventory_id
                                }) - Quantity: ${product.quantity}  Length: ${
                                  product.init_slab_length || 0
                                }mm Width: ${
                                  product.init_slab_weidth || 0
                                }mm vendor: ${product.vendor_id}`,
                              }))}
                            id={`product_id_`}
                            name="product_id"
                            value={selectedProduct}
                            onChange={(value) =>
                              handleProductSelectChange(value)
                            }
                            placeholder="Select Product Id"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="checkbox"
                            id={`custom_size_`}
                            name="custom_size"
                            checked={formData.product?.customSize}
                            onChange={(e) => handleCustomSizeChange(e)}
                          />
                          <label htmlFor={`custom_size_`} className="ml-2">
                            Custom Size (If any)
                          </label>
                        </div>

                        {formData.product?.customSize && (
                          <div className="custom-size-fields">
                            <div className="form-group">
                              <label>Unit</label>
                              <Select
                                options={[
                                  { value: "mm", label: "Millimeters (mm)" },
                                  { value: "Inch", label: "Inches (Inch)" },
                                ]}
                                value={{
                                  value: lengthUnit,
                                  label:
                                    lengthUnit === "mm"
                                      ? "Millimeters (mm)"
                                      : "Inches (Inch)",
                                }}
                                onChange={(option) =>
                                  setLengthUnit(option.value)
                                }
                                isClearable
                                placeholder="Select Unit"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="custom_length">Length</label>
                              <input
                                type="number"
                                className="form-control"
                                id="custom_length"
                                name="custom_length"
                                value={formData.product.custom_length}
                                onChange={handleProductChange}
                                placeholder={`Enter Length (${lengthUnit})`}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="custom_width">Width</label>
                              <input
                                type="number"
                                className="form-control"
                                id="custom_width"
                                name="custom_width"
                                value={formData.product.custom_width}
                                onChange={handleProductChange}
                                placeholder={`Enter Width (${lengthUnit})`}
                              />
                            </div>

                            {formData.product?.area && (
                              <div className="form-group">
                                <label>Area:</label>
                                <div>{formData.product?.area} mm²</div>
                                <div>
                                  Waste Length:{" "}
                                  {
                                    calculateWaste(
                                      selectedProduct,
                                      formData.product?.custom_length,
                                      formData.product?.custom_width
                                    ).wasteLength
                                  }{" "}
                                  mm
                                </div>
                                <div>
                                  Waste Width:{" "}
                                  {
                                    calculateWaste(
                                      selectedProduct,
                                      formData.product?.custom_length,
                                      formData.product?.custom_width
                                    ).wasteWidth
                                  }{" "}
                                  mm
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="form-group">
                          <label htmlFor={`quantity_`}>Quantity</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 24"
                            id={`quantity_`}
                            name="quantity"
                            value={formData.product?.quantity}
                            onChange={(e) => handleProductChange(e)}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor={`price_`}>Price/piece</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 24"
                            id={`price_`}
                            name="price"
                            value={formData.product?.price}
                            onChange={(e) => handleProductChange(e)}
                            required
                          />
                        </div>
                      </div>
                    )}

                    <div className="d-flex">
                      <div className="col-md-6">
                        {formData.product?.product_id?.length > 0 && (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm me-2 mb-2"
                            onClick={() => removeProduct()}
                            title="Remove Product"
                          >
                            <i className="fas fa-times-circle"></i>
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-success btn-sm me-2 mb-2"
                          onClick={addProduct}
                          title="Add Another Product"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      {productData.length > 0 && (
                        <>
                          <div className="mb-3">
                            <label htmlFor="form-label">Discount Amount</label>
                            <div className="input-group">
                              <select
                                className="form-select"
                                onChange={(e) =>
                                  setDiscountType(e.target.value)
                                }
                              >
                                <option value="plain">Plain</option>
                                <option value="percentage">Percentage</option>
                              </select>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="If any"
                                id="discount_amount_"
                                name="discount_amount"
                                value={formData.product?.discount_amount}
                                onChange={handleDiscountAmountChange}
                                min={0}
                                max={discountType === "percentage" ? 100 : ""}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor={`carring_charge_`}>
                              Carring Charge
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="If any"
                              id={`carring_charge`}
                              name="carring_charge"
                              value={formData.product?.carring_charge}
                              onChange={(e) => setCarringCharge(e.target.value)}
                            />
                          </div>

                          <button
                            type="button"
                            className="btn btn-primary btn-sm me-2 mb-2"
                            onClick={handleSubmit}
                          >
                            Add Sell
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="card mb-5 border-0 shadow">
                <div className="card-header py-3 text-center fw-bold">
                  <h3 className="card-title mb-0 fw-bold">Products</h3>
                </div>
                <div className="card-body table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">Product ID</th>
                        <th className="text-center">Product Name</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Length (mm)</th>
                        <th className="text-center">Width (mm)</th>

                        <th className="text-center">Area</th>
                        <th className="text-center">Category Id</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productData.map((product, idx) => (
                        <tr key={product.product_id}>
                          <td className="text-secondary text-center">
                            {product.product_id || "N/A"}
                          </td>
                          <td className="text-secondary text-center">
                            {product.product_name || "N/A"}
                          </td>
                          <td className="text-secondary text-center">
                            {product.quantity || "N/A"}
                          </td>
                          <td className="text-secondary text-center">
                            {product.price || "N/A"}
                          </td>
                          <td className="text-secondary text-center">
                            {product.custom_length || "N/A"}
                          </td>
                          <td className="text-secondary text-center">
                            {product.custom_width || "N/A"}
                          </td>
                          {/* New table data for waste length and width */}

                          {/* End of new table data */}
                          <td className="text-secondary text-center">
                            {product.area || "N/A"}
                          </td>
                          <td className="text-secondary text-center">
                            {product.unique_category_id || "N/A"}
                          </td>

                          <td className="text-secondary text-center">
                            {/* Modify this if you want to update product */}
                            {/* <button
                              className="btn btn-primary btn-sm mr-2"
                              onClick={() => handleUpdate(product)}
                              title="Update"
                            >
                              <i className="fas fa-edit mr-1"></i>
                            </button> */}
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleProductDelete(idx)}
                              title="Delete"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th scope="col" colSpan={6}></th>
                        <th
                          scope="col"
                          colSpan={4}
                          className="text-nowrap text-justify"
                        >
                          {getTotalPrice > 0 && (
                            <div>
                              <p className="w-100 d-flex justify-content-between">
                                <span>Total:</span>{" "}
                                <span>{getTotalPrice}৳</span>
                              </p>
                              <p className="w-100 d-flex justify-content-between">
                                <span>Discount:</span>{" "}
                                <span>{discountAmount}৳</span>
                              </p>

                              <p className="w-100 d-flex justify-content-between">
                                <span>After discount: </span>{" "}
                                <span>{getTotalPrice - discountAmount}৳</span>
                              </p>
                              <p className="w-100 d-flex justify-content-between">
                                <span>Carring Charge:</span>{" "}
                                <span>{carryingCharge}৳</span>
                              </p>
                              <p className="w-100 d-flex justify-content-between">
                                <span>Grand Total: </span>{" "}
                                <span>
                                  {getTotalPrice -
                                    discountAmount +
                                    parseFloat(carryingCharge)}
                                  ৳
                                </span>
                              </p>
                            </div>
                          )}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="card last-five-list-card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Latest Five Sell</h3>
                </div>
                <div className="card-body table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">Sell ID</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Address</th>
                        <th className="text-center">Phone number</th>
                        <th className="text-center">Total Price</th>
                        <th className="text-center">Sell Date</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveSells.map((sell) => (
                        <tr key={sell.sell_id}>
                          <td className="text-secondary text-center">
                            {sell.sell_id}
                          </td>
                          <td className="text-secondary text-center">
                            {sell.customer_name}
                          </td>
                          <td className="text-secondary text-center">
                            {sell.customer_address}
                          </td>
                          <td className="text-secondary text-center">
                            {sell.customer_phone_number}
                          </td>
                          <td className="text-secondary text-center">
                            {sell.total_price}
                          </td>
                          <td className="text-secondary text-center">
                            {new Date(sell.createdAt).toLocaleDateString()}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm mr-2"
                              onClick={() => handleDetails(sell)}
                              title="More"
                            >
                              <i className="fas fa-bars mr-1"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SellDetailsModal
        isOpen={showDetailsModal}
        onRequestClose={() => setShowDetailsModal(false)}
        sellDetails={sellToShow}
      />
    </div>
  );
};

export default SellIndexAdd;
