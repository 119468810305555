import React from 'react';

const removeHTMLTags = (str) => {
  return str.replace(/<[^>]*>?/gm, '').replace(/(?:\r\n|\r|\n)/g, '<br/>');
};

const NoticeDetailsModal = ({ notice, onClose }) => {
  const descriptionWithoutHTML = removeHTMLTags(notice.notice_description);

  return (
    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{notice.notice_title}</h5>
            <button type="button" className="close" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          <p><strong>Publish Date & Time:</strong> {new Date(notice.publish_datetime).toLocaleString()}</p>

            <p><strong>Description:</strong> <span dangerouslySetInnerHTML={{ __html: descriptionWithoutHTML }} /></p>
            {/* Add more notice details here if needed */}
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NoticeDetailsModal;
