import React, { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth";

const Navbar = ({ currentPage }) => {
  const [greeting, setGreeting] = useState("");
  const { logout, user } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    let newGreeting;

    if (currentHour >= 5 && currentHour < 12) {
      newGreeting = "Good morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      newGreeting = "Good afternoon";
    } else {
      newGreeting = "Good evening";
    }

    setGreeting(newGreeting);
  }, []);

  return (
    <nav className="main-header navbar navbar-expand">
      <div className="container-fluid">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link text-dark fw-bold ms-2"
              title={currentPage}
              href={`/${currentPage.toLowerCase()}`}
              role="button"
            >
              {/* {currentPage.charAt(0).toUpperCase() + currentPage.slice(1)} */}
              {currentPage.replace(/\//g, " ").toUpperCase()}
            </a>
          </li>

          {/* Add more navbar items here */}
        </ul>

        {/* Right side of the navbar */}
        <ul className="navbar-nav ml-auto">
          {user && (
            <>
              <li className="nav-item">
                <span className="nav-link text-dark">
                  {greeting}, {user.user.name}
                </span>
              </li>

              {/* <li className="nav-item">
                <span className="nav-link text-white">
                  Time since login: {formatTime(loggedInTime)}
                </span>
              </li> */}

              <li className="nav-item">
                <Link
                  to="/login"
                  className="btn btn-link text-dark"
                  title="Logout"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default Link behavior
                    handleLogout(); // Call handleLogout function
                  }}
                >
                  <i className="fas fa-sign-out-alt"></i>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
